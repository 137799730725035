import React, { useState, useEffect, } from "react";
import { getDoc, getDocs, doc, setDoc, updateDoc, onSnapshot, collection, addDoc, Timestamp, serverTimestamp } from 'firebase/firestore';
import { useNavigate, useParams, Link, useHistory, useNavigation } from 'react-router-dom';
import { getAuth, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { FaUser, FaArrowLeft, FaTv, FaTrash } from 'react-icons/fa';
import { db, app } from "../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { Menu } from "./AccueilAdmin";
import Logo from '../../assets/logo/logo-horizontale-v2.png';
import { useTranslation } from 'react-i18next';
import '../../css/style.css'

export function UpdateRankings() {
    const { t } = useTranslation();
    const [fighterData, setFighterData] = useState([]);
    const [id, setId] = useState('');
    const [division, setDivision] = useState("");
    const [gender, setGender] = useState("");
    const [lastName, setLastName] = useState("");
    const [org, setOrg] = useState("");
    const [rankings, setRankings] = useState([]);
    const [rankingReady, setRankingReady] = useState(false);
    const [champ, setChamp] = useState("");
    const [interim, setInterim] = useState("");


    const [nums, setNums] = useState({
        num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },

    });

    const [previousNums, setPreviousNums] = useState({
        num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },

    });

    const [orgs, setOrgs] = useState([])


    const genders = [{ value: "men", label: "MEN" }, { value: "women", label: "WOMEN" }];


    const divisions = gender === "men" ? [{ value: "1heavy", label: "HEAVYWEIGHT" },
    { value: "2lightheavy", label: "LIGHT-HEAVYWEIGHT" },
    { value: "3middle", label: "MIDDLEWEIGHT" },
    { value: "4welter", label: "WELTERWEIGHT" },
    { value: "5light", label: "LIGHTWEIGHT" },
    { value: "6feather", label: "FEATHERWEIGHT" },
    { value: "7bantam", label: "BANTAMWEIGHT" },
    { value: "8fly", label: "FLYWEIGHT" }] :
        [{ value: "6feather", label: "WOMEN FEATHERWEIGHT" },
        { value: "7bantam", label: "WOMEN BANTAMWEIGHT" },
        { value: "8fly", label: "WOMEN FLYWEIGHT" },
        { value: "9straw", label: "WOMEN STRAWWEIGHT" },
        { value: "10atom", label: "WOMEN ATOMWEIGHT" },];


    const navigate = useNavigate();

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    const [formulaireVisible, setFormulaireVisible] = useState(false);
    const [previousRankingsVisible, setPreviousRankingsVisible] = useState(false);

    /////////// RECHERCHE
    ////////////// NOM COMBATTANTS //////////////

    const [filteredFighters, setFilteredFighters] = useState([]);

    const [searchChamp, setSearchChamp] = useState('');
    const [searchInterim, setSearchInterim] = useState('');

    // Créez une instance de l'index Algolia

    const fetchFighters = async (search, orga, gender, attributesToRetrieve = ['id']) => {
        const functions = getFunctions(app);
        const searchFighters = httpsCallable(functions, 'searchFighters');

        let fetchedData = [];

        try {
            // Créez une requête de recherche Algolia sans filtres
            const result = await searchFighters({
                search,
                orga,
                gender,
                attributesToRetrieve
            });

            // Ajoutez tous les résultats de recherche à fetchedData
            if (result.data && Array.isArray(result.data.hits)) {
                fetchedData = result.data.hits.map(hit => ({
                    id: hit.id,
                    ...hit
                }));
            } else {
                console.log('No results from Algolia search or data structure is unexpected.');
            }
        } catch (error) {
            console.error('Error loading favorite fighters data:', error);
        }

        return fetchedData;
    };
    //////////// TEST /////////////
    const [searches, setSearches] = useState({
        search1: '',
        search2: '',
        search3: '',
        search4: '',
        search5: '',
        search6: '',
        search7: '',
        search8: '',
        search9: '',
        search10: '',
        search11: '',
        search12: '',
        search13: '',
        search14: '',
        search15: '',

    });

    const handleSearchChange = (number, value) => {
        setSearches(prevSearches => ({
            ...prevSearches,
            [`search${number}`]: value
        }));
    };
    const handleNumChange = (number, value) => {
        setNums(prevNums => ({
            ...prevNums,
            [`num${number}`]: {
                ...prevNums[`num${number}`],
                id: value

            }

        }));

    };

    const handleStatsChange = (number, field, value) => {
        setNums(prevNums => ({
            ...prevNums,
            [`num${number}`]: {
                ...prevNums[`num${number}`],
                [field]: value
            }
        }));
    };



    useEffect(() => {
        // Ici, vous pouvez itérer sur chaque clé de l'objet searches
        Object.entries(searches).forEach(([key, value]) => {
            if (value) {
                const fetchFightersData = async () => {
                    const fetchedData = await fetchFighters(value, org, gender);
                    const results = fetchedData.filter(fighter =>
                        fighter.id && fighter.id.toLowerCase().includes(value.toLowerCase())
                    );

                    setFilteredFighters(results);
                };

                fetchFightersData();
            }
        });
    }, [searches]);

    /////////////////////////////

    useEffect(() => { // CHAMP
        if (searchChamp) {
            const fetchFightersData = async () => {
                const fetchedData = await fetchFighters(searchChamp, org, gender);

                // After fetching, filter the data based on the search term
                const results = fetchedData.filter(fighter =>
                    fighter.id && fighter.id.toLowerCase().includes(searchChamp.toLowerCase())
                );


                setFilteredFighters(results);
            };

            fetchFightersData();
        }
    }, [searchChamp]);

    useEffect(() => { // INTERIM
        if (searchInterim) {
            const fetchFightersData = async () => {
                const fetchedData = await fetchFighters(searchInterim, org, gender);

                // After fetching, filter the data based on the search term
                const results = fetchedData.filter(fighter =>
                    fighter.id && fighter.id.toLowerCase().includes(searchInterim.toLowerCase())
                );


                setFilteredFighters(results);
            };

            fetchFightersData();
        }
    }, [searchInterim]);

    // Charger les rankings
    useEffect(() => {
        const fetchRankings = async () => {

            if (org && gender && division) {

                const docRef = doc(db, "fightData", "organisations", org, "rankings", gender, division);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setRankings(data);

                } else {
                    setRankings([]);

                }
            }

            setRankingReady(true);
        };

        setSearchChamp(''); setSearchInterim('');
        setSearches({
            search1: '', search2: '', search3: '', search4: '', search5: '', search6: '', search7: '',
            search8: '', search9: '', search10: '', search11: '', search12: '', search13: '', search14: '', search15: ''
        })

        setChamp('');
        setInterim('');
        setNums({
            num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        })
        setPreviousNums({
            num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        })
        setRankingReady(false);
        fetchRankings();
    }, [org, gender, division]);

    useEffect(() => { //récupération rankings
        if (rankings && Object.keys(rankings).length > 0 && rankingReady) {

            // Créer un tableau pour stocker les IDs des documents
            const ids = [];

            const fetchDocIds = async () => {
                // Liste des clés que vous voulez parcourir
                const keys = [...Array(15).keys()].map(num => (num + 1).toString()).concat(['interim', 'champ']);

                for (let key of keys) {
                    if (rankings[key]) {
                        const docSnap = await getDoc(rankings[key]);

                        if (docSnap.exists()) {
                            ids.push({ [key]: docSnap.id }); // Ajoutez l'ID du document avec sa clé respective
                        }
                    }
                }

                // Récupération des IDs en fonction de leurs clés
                const champId = ids.find(idObj => idObj.champ)?.champ;
                const interimId = ids.find(idObj => idObj.interim)?.interim;
                const num1Id = ids.find(idObj => idObj["1"])?.["1"]; const num9Id = ids.find(idObj => idObj["9"])?.["9"];
                const num2Id = ids.find(idObj => idObj["2"])?.["2"]; const num10Id = ids.find(idObj => idObj["10"])?.["10"];
                const num3Id = ids.find(idObj => idObj["3"])?.["3"]; const num11Id = ids.find(idObj => idObj["11"])?.["11"];
                const num4Id = ids.find(idObj => idObj["4"])?.["4"]; const num12Id = ids.find(idObj => idObj["12"])?.["12"];
                const num5Id = ids.find(idObj => idObj["5"])?.["5"]; const num13Id = ids.find(idObj => idObj["13"])?.["13"];
                const num6Id = ids.find(idObj => idObj["6"])?.["6"]; const num14Id = ids.find(idObj => idObj["14"])?.["14"];
                const num7Id = ids.find(idObj => idObj["7"])?.["7"]; const num15Id = ids.find(idObj => idObj["15"])?.["15"];
                const num8Id = ids.find(idObj => idObj["8"])?.["8"];

                // Mise à jour des états



                setChamp(champId);
                setInterim(interimId);

                if (org !== 'PFL') {
                    setNums(prevNums => ({ ...prevNums, [[`num1`]]: { id: num1Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num2`]]: { id: num2Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num3`]]: { id: num3Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num4`]]: { id: num4Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num5`]]: { id: num5Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num6`]]: { id: num6Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num7`]]: { id: num7Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num8`]]: { id: num8Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num9`]]: { id: num9Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num10`]]: { id: num10Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num11`]]: { id: num11Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num12`]]: { id: num12Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num13`]]: { id: num13Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num14`]]: { id: num14Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num15`]]: { id: num15Id } }));

                    // ENREGISTRER CLASSEMENT AVANT MODIFICATION POUR PREVIOUS RANKING

                    setPreviousNums(prevNums => ({ ...prevNums, [[`num1`]]: { id: num1Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num2`]]: { id: num2Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num3`]]: { id: num3Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num4`]]: { id: num4Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num5`]]: { id: num5Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num6`]]: { id: num6Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num7`]]: { id: num7Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num8`]]: { id: num8Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num9`]]: { id: num9Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num10`]]: { id: num10Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num11`]]: { id: num11Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num12`]]: { id: num12Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num13`]]: { id: num13Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num14`]]: { id: num14Id } }));
                    setPreviousNums(prevNums => ({ ...prevNums, [[`num15`]]: { id: num15Id } }));

                } else {
                    const newNums = {};
                    // Assuming `data` is an object where each key corresponds to `numX` and its value is an object with fighter details
                    Object.keys(rankings).forEach((key, index) => {
                        // Make sure we only work within the limits of existing `nums` keys

                        const fighterData = rankings[key];

                        if (fighterData.fighterRef && typeof fighterData.fighterRef === 'object' && fighterData.fighterRef.id) {
                            newNums[`num${index + 1}`] = {
                                id: fighterData.fighterRef.id,  // Assuming this needs to be a reference ID or similar
                                win: fighterData.win || 0,
                                lose: fighterData.lose || 0,
                                draw: fighterData.draw || 0,
                                points: fighterData.points || 0,
                                totalTime: fighterData.totalTime || '',
                            };
                        }
                    });
                    setNums(newNums);
                    setPreviousNums(newNums);
                }

            };

            fetchDocIds();
        } else {
            console.log("no Rankings setNums");
        }
    }, [rankings, rankingReady]);

    /// formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (org !== 'PFL') {
            try {
                const rankingRef = doc(db, "fightData", "organisations", org, "rankings", gender, division);
                const previousRankingRef = doc(db, "fightData", "organisations", org, "rankings", gender, division, 'previousRanking', division);

                const updateObject = {
                    ...(champ !== "" && champ && { champ: doc(db, "fighterData", champ) }),
                    ...(interim !== "" && interim && { interim: doc(db, "fighterData", interim) }),
                    ...(nums[`num1`].id !== "" && nums[`num1`].id && { ["1"]: doc(db, "fighterData", nums[`num1`].id) }),
                    ...(nums[`num2`].id !== "" && nums[`num2`].id && { ["2"]: doc(db, "fighterData", nums[`num2`].id) }),
                    ...(nums[`num3`].id !== "" && nums[`num3`].id && { ["3"]: doc(db, "fighterData", nums[`num3`].id) }),
                    ...(nums[`num4`].id !== "" && nums[`num4`].id && { ["4"]: doc(db, "fighterData", nums[`num4`].id) }),
                    ...(nums[`num5`].id !== "" && nums[`num5`].id && { ["5"]: doc(db, "fighterData", nums[`num5`].id) }),
                    ...(nums[`num6`].id !== "" && nums[`num6`].id && { ["6"]: doc(db, "fighterData", nums[`num6`].id) }),
                    ...(nums[`num7`].id !== "" && nums[`num7`].id && { ["7"]: doc(db, "fighterData", nums[`num7`].id) }),
                    ...(nums[`num8`].id !== "" && nums[`num8`].id && { ["8"]: doc(db, "fighterData", nums[`num8`].id) }),
                    ...(nums[`num9`].id !== "" && nums[`num9`].id && { ["9"]: doc(db, "fighterData", nums[`num9`].id) }),
                    ...(nums[`num10`].id !== "" && nums[`num10`].id && { ["10"]: doc(db, "fighterData", nums[`num10`].id) }),
                    ...(nums[`num11`].id !== "" && nums[`num11`].id && { ["11"]: doc(db, "fighterData", nums[`num11`].id) }),
                    ...(nums[`num12`].id !== "" && nums[`num12`].id && { ["12"]: doc(db, "fighterData", nums[`num12`].id) }),
                    ...(nums[`num13`].id !== "" && nums[`num13`].id && { ["13"]: doc(db, "fighterData", nums[`num13`].id) }),
                    ...(nums[`num14`].id !== "" && nums[`num14`].id && { ["14"]: doc(db, "fighterData", nums[`num14`].id) }),
                    ...(nums[`num15`].id !== "" && nums[`num15`].id && { ["15"]: doc(db, "fighterData", nums[`num15`].id) }),
                    lastUpdate: serverTimestamp()
                };

                const previousObject = {
                    ...(previousNums[`num1`].id !== "" && previousNums[`num1`].id && { ["1"]: doc(db, "fighterData", previousNums[`num1`].id) }),
                    ...(previousNums[`num2`].id !== "" && previousNums[`num2`].id && { ["2"]: doc(db, "fighterData", previousNums[`num2`].id) }),
                    ...(previousNums[`num3`].id !== "" && previousNums[`num3`].id && { ["3"]: doc(db, "fighterData", previousNums[`num3`].id) }),
                    ...(previousNums[`num4`].id !== "" && previousNums[`num4`].id && { ["4"]: doc(db, "fighterData", previousNums[`num4`].id) }),
                    ...(previousNums[`num5`].id !== "" && previousNums[`num5`].id && { ["5"]: doc(db, "fighterData", previousNums[`num5`].id) }),
                    ...(previousNums[`num6`].id !== "" && previousNums[`num6`].id && { ["6"]: doc(db, "fighterData", previousNums[`num6`].id) }),
                    ...(previousNums[`num7`].id !== "" && previousNums[`num7`].id && { ["7"]: doc(db, "fighterData", previousNums[`num7`].id) }),
                    ...(previousNums[`num8`].id !== "" && previousNums[`num8`].id && { ["8"]: doc(db, "fighterData", previousNums[`num8`].id) }),
                    ...(previousNums[`num9`].id !== "" && previousNums[`num9`].id && { ["9"]: doc(db, "fighterData", previousNums[`num9`].id) }),
                    ...(previousNums[`num10`].id !== "" && previousNums[`num10`].id && { ["10"]: doc(db, "fighterData", previousNums[`num10`].id) }),
                    ...(previousNums[`num11`].id !== "" && previousNums[`num11`].id && { ["11"]: doc(db, "fighterData", previousNums[`num11`].id) }),
                    ...(previousNums[`num12`].id !== "" && previousNums[`num12`].id && { ["12"]: doc(db, "fighterData", previousNums[`num12`].id) }),
                    ...(previousNums[`num13`].id !== "" && previousNums[`num13`].id && { ["13"]: doc(db, "fighterData", previousNums[`num13`].id) }),
                    ...(previousNums[`num14`].id !== "" && previousNums[`num14`].id && { ["14"]: doc(db, "fighterData", previousNums[`num14`].id) }),
                    ...(previousNums[`num15`].id !== "" && previousNums[`num15`].id && { ["15"]: doc(db, "fighterData", previousNums[`num15`].id) })
                };

                await setDoc(rankingRef, updateObject);
                await setDoc(previousRankingRef, previousObject);
                setDivision('');
                setSearchChamp(''); setSearchInterim('');
                setSearches({
                    search1: '', search2: '', search3: '', search4: '', search5: '', search6: '', search7: '',
                    search8: '', search9: '', search10: '', search11: '', search12: '', search13: '', search14: '', search15: ''
                })
                setChamp('');
                setInterim('');
                setNums({
                    num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                })
                setPreviousNums({
                    num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                })
                setFormulaireVisible(false);

                alert("Classement soumis avec succès !");
            } catch (error) {
                console.error("Error adding document: ", error);
            }

        } else {
            try {
                const articleRef = doc(db, "fightData", "organisations", org, "rankings", gender, division);

                const updateObject = {
                    ...(champ !== "" && champ && { champ: doc(db, "fighterData", champ) }),
                    ...(interim !== "" && interim && { interim: doc(db, "fighterData", interim) }),
                    ...(nums[`num1`] && nums[`num1`].id && nums[`num1`].id !== "" && {
                        [nums[`num1`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num1`].id),
                            win: nums[`num1`].win,
                            lose: nums[`num1`].lose,
                            draw: nums[`num1`].draw,
                            points: nums[`num1`].points,
                            totalTime: nums[`num1`].totalTime,
                        }
                    }),
                    ...(nums[`num2`] && nums[`num2`].id && nums[`num2`].id !== "" && {
                        [nums[`num2`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num2`].id),
                            win: nums[`num2`].win,
                            lose: nums[`num2`].lose,
                            draw: nums[`num2`].draw,
                            points: nums[`num2`].points,
                            totalTime: nums[`num2`].totalTime,
                        }
                    }),
                    ...(nums[`num3`] && nums[`num3`].id && nums[`num3`].id !== "" && {
                        [nums[`num3`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num3`].id),
                            win: nums[`num3`].win,
                            lose: nums[`num3`].lose,
                            draw: nums[`num3`].draw,
                            points: nums[`num3`].points,
                            totalTime: nums[`num3`].totalTime,
                        }
                    }),
                    ...(nums[`num4`] && nums[`num4`].id && nums[`num4`].id !== "" && {
                        [nums[`num4`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num4`].id),
                            win: nums[`num4`].win,
                            lose: nums[`num4`].lose,
                            draw: nums[`num4`].draw,
                            points: nums[`num4`].points,
                            totalTime: nums[`num4`].totalTime,
                        }
                    }),
                    ...(nums[`num5`] && nums[`num5`].id && nums[`num5`].id !== "" && {
                        [nums[`num5`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num5`].id),
                            win: nums[`num5`].win,
                            lose: nums[`num5`].lose,
                            draw: nums[`num5`].draw,
                            points: nums[`num5`].points,
                            totalTime: nums[`num5`].totalTime,
                        }
                    }),
                    ...(nums[`num6`] && nums[`num6`].id && nums[`num6`].id !== "" && {
                        [nums[`num6`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num6`].id),
                            win: nums[`num6`].win,
                            lose: nums[`num6`].lose,
                            draw: nums[`num6`].draw,
                            points: nums[`num6`].points,
                            totalTime: nums[`num6`].totalTime,
                        }
                    }),
                    ...(nums[`num7`] && nums[`num7`].id && nums[`num7`].id !== "" && {
                        [nums[`num7`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num7`].id),
                            win: nums[`num7`].win,
                            lose: nums[`num7`].lose,
                            draw: nums[`num7`].draw,
                            points: nums[`num7`].points,
                            totalTime: nums[`num7`].totalTime,
                        }
                    }),
                    ...(nums[`num8`] && nums[`num8`].id && nums[`num8`].id !== "" && {
                        [nums[`num8`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num8`].id),
                            win: nums[`num8`].win,
                            lose: nums[`num8`].lose,
                            draw: nums[`num8`].draw,
                            points: nums[`num8`].points,
                            totalTime: nums[`num8`].totalTime,
                        }
                    }),
                    ...(nums[`num9`] && nums[`num9`].id && nums[`num9`].id !== "" && {
                        [nums[`num9`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num9`].id),
                            win: nums[`num9`].win,
                            lose: nums[`num9`].lose,
                            draw: nums[`num9`].draw,
                            points: nums[`num9`].points,
                            totalTime: nums[`num9`].totalTime,
                        }
                    }),
                    ...(nums[`num10`] && nums[`num10`].id && nums[`num10`].id !== "" && {
                        [nums[`num10`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num10`].id),
                            win: nums[`num10`].win,
                            lose: nums[`num10`].lose,
                            draw: nums[`num10`].draw,
                            points: nums[`num10`].points,
                            totalTime: nums[`num10`].totalTime,
                        }
                    }),
                    ...(nums[`num11`] && nums[`num11`].id && nums[`num11`].id !== "" && {
                        [nums[`num11`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num11`].id),
                            win: nums[`num11`].win,
                            lose: nums[`num11`].lose,
                            draw: nums[`num11`].draw,
                            points: nums[`num11`].points,
                            totalTime: nums[`num11`].totalTime,
                        }
                    }),
                    ...(nums[`num12`] && nums[`num12`].id && nums[`num12`].id !== "" && {
                        [nums[`num12`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num12`].id),
                            win: nums[`num12`].win,
                            lose: nums[`num12`].lose,
                            draw: nums[`num12`].draw,
                            points: nums[`num12`].points,
                            totalTime: nums[`num12`].totalTime,
                        }
                    }),
                    ...(nums[`num13`] && nums[`num13`].id && nums[`num13`].id !== "" && {
                        [nums[`num13`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num13`].id),
                            win: nums[`num13`].win,
                            lose: nums[`num13`].lose,
                            draw: nums[`num13`].draw,
                            points: nums[`num13`].points,
                            totalTime: nums[`num13`].totalTime,
                        }
                    }),
                    ...(nums[`num14`] && nums[`num14`].id && nums[`num14`].id !== "" && {
                        [nums[`num14`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num14`].id),
                            win: nums[`num14`].win,
                            lose: nums[`num14`].lose,
                            draw: nums[`num14`].draw,
                            points: nums[`num14`].points,
                            totalTime: nums[`num14`].totalTime,
                        }
                    }),
                    ...(nums[`num15`] && nums[`num15`].id && nums[`num15`].id !== "" && {
                        [nums[`num15`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num15`].id),
                            win: nums[`num15`].win,
                            lose: nums[`num15`].lose,
                            draw: nums[`num15`].draw,
                            points: nums[`num15`].points,
                            totalTime: nums[`num15`].totalTime,
                        }
                    })
                };

                await setDoc(articleRef, updateObject);
                setDivision('');
                setSearchChamp(''); setSearchInterim('');
                setSearches({
                    search1: '', search2: '', search3: '', search4: '', search5: '', search6: '', search7: '',
                    search8: '', search9: '', search10: '', search11: '', search12: '', search13: '', search14: '', search15: ''
                })
                setChamp('');
                setInterim('');
                setNums({
                    num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                })
                setFormulaireVisible(false);

                alert("Classement soumis avec succès !");
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        }


    };


    /////////// BOUTON ////////////
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };
    ////////////
    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                position: 'absolute', top: '0', height: '60px', width: '100%', display: 'flex',
                justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', padding: '0 20px'
            }}>
                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <img src={Logo} alt="Logo" style={{ height: '250px' }} />
                    <h2 style={{ margin: 0, color: 'white', fontSize: '1.2em' }}>CLASSEMENT</h2>
                </div>

                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <button onClick={toggleMenu} style={{
                        padding: '8px 16px', border: 'none', backgroundColor: 'white', color: '#ff3030',
                        borderRadius: '25px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginRight: '10px'
                    }}>
                        {isMenuOpen ? t('Close the menu') : t('Open the menu')}
                    </button>

                    <button onClick={handleLogout} style={{
                        background: 'none', border: '1px solid white', color: 'white', padding: '8px 16px',
                        borderRadius: '25px', cursor: 'pointer', fontWeight: 'bolder'
                    }}>
                        Logout
                    </button>
                </div>
            </div>

            {isMenuOpen && (
                <Menu />
            )}

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("SEARCH A RANKING")}</h2>

                <div style={{ backgroundColor: '#ff3030', padding: '20px', borderRadius: 10 }}>
                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white', fontWeight: 'bold' }}>Organization :</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // Genre
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white', fontWeight: 'bold' }}>{t("Gender")} :</label>
                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a gender")}</option>
                            {genders.map((gender, index) => (
                                <option key={index} value={gender.value}>
                                    {gender.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // division
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white', fontWeight: 'bold' }}>{t("Division")} :</label>
                        <select
                            value={division}
                            onChange={(e) => {
                                setDivision(e.target.value); if (e.target.value !== "") {
                                    setFormulaireVisible(true);
                                }
                            }}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a division")}</option>
                            {divisions.map((division, index) => (
                                <option key={index} value={division.value}>
                                    {division.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {formulaireVisible ? (
                    <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>

                        <div style={{ // CHAMP
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>CHAMPION :</label>

                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', }}>
                                <input
                                    type="text"
                                    value={searchChamp}
                                    onChange={(e) => setSearchChamp(e.target.value)}
                                    placeholder={champ ? champ : "Search a fighter..."}
                                    className={champ ? "custom-placeholder-input" : null}
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }} />

                                {champ ?
                                    <button onClick={() => setChamp('')}
                                        style={{
                                            padding: '10px 10px', border: 'none', backgroundColor: '#383838', marginLeft: '10px',
                                            color: 'white', borderRadius: '8px', cursor: 'pointer', marginRight: '10px',
                                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                        }}>
                                        <FaTrash />
                                    </button>
                                    : null}
                            </li>

                            {searchChamp && (
                                <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                                    {filteredFighters.map((fighter, index) => (
                                        <div
                                            key={index}
                                            style={{ padding: '10px', cursor: 'pointer' }}
                                            onClick={() => {
                                                setChamp(fighter.id)
                                                setFilteredFighters([])
                                                setSearchChamp('');  // optionnel: vider le champ de recherche lors de la sélection
                                            }}
                                        >
                                            {fighter.id}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div style={{ // INTERIM
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>INTERIM CHAMPION :</label>

                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', }}>
                                <input
                                    type="text"
                                    value={searchInterim}
                                    onChange={(e) => setSearchInterim(e.target.value)}
                                    placeholder={interim ? interim : "Search a fighter..."}
                                    className={interim ? "custom-placeholder-input" : null}
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }} />

                                {interim ?
                                    <button onClick={() => setInterim('')}
                                        style={{
                                            padding: '10px 10px', border: 'none', backgroundColor: '#383838', marginLeft: '10px',
                                            color: 'white', borderRadius: '8px', cursor: 'pointer', marginRight: '10px',
                                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                        }}>
                                        <FaTrash />
                                    </button>
                                    : null}
                            </li>

                            {searchInterim && (
                                <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                                    {filteredFighters.map((fighter, index) => (
                                        <div
                                            key={index}
                                            style={{ padding: '10px', cursor: 'pointer' }}
                                            onClick={() => {
                                                setInterim(fighter.id)
                                                setFilteredFighters([])
                                                setSearchInterim('');  // optionnel: vider le champ de recherche lors de la sélection
                                            }}
                                        >
                                            {fighter.id}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {Array.from({ length: 15 }, (_, index) => index + 1).map(number => (
                            <div style={{
                                width: '100%', marginBottom: '10px'
                            }}>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                    {org !== 'PFL' ?
                                        <label style={{ marginRight: 5 }}>{number}</label>
                                        : null}

                                    <input
                                        type="text"
                                        value={searches[`search${number}`]}
                                        onChange={(e) => handleSearchChange(number, e.target.value)}
                                        placeholder={nums[`num${number}`] && nums[`num${number}`].id ? nums[`num${number}`].id : "Search a fighter..."}
                                        className={nums[`num${number}`] && nums[`num${number}`].id ? "custom-placeholder-input" : null}
                                        style={{
                                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none',
                                        }} />

                                    {nums[`num${number}`] ?
                                        <button type="button" onClick={() => handleNumChange(number, '')}
                                            style={{
                                                padding: '10px 10px', border: 'none', backgroundColor: '#383838', marginLeft: '10px',
                                                color: 'white', borderRadius: '8px', cursor: 'pointer', marginRight: '10px',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                            }}>
                                            <FaTrash />
                                        </button>
                                        : null}

                                    {searches[`search${number}`] && (
                                        <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                                            {filteredFighters.map((fighter, index) => (
                                                <div
                                                    key={index}
                                                    style={{ padding: '10px', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleNumChange(number, fighter.id);
                                                        setFilteredFighters([]);
                                                        handleSearchChange(number, ''); // Vider le champ de recherche lors de la sélection
                                                    }}
                                                >
                                                    {fighter.id}
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </div>

                                {nums[`num${number}`] && nums[`num${number}`].id && org === 'PFL' && (
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input
                                                type="number"
                                                value={nums[`num${number}`].win}
                                                onChange={(e) => handleStatsChange(number, 'win', parseInt(e.target.value))}
                                                placeholder="Wins"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>Wins</label>
                                        </div>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input
                                                type="number"
                                                value={nums[`num${number}`].lose}
                                                onChange={(e) => handleStatsChange(number, 'lose', parseInt(e.target.value))}
                                                placeholder="Losses"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>Losses</label>
                                        </div>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input
                                                type="number"
                                                value={nums[`num${number}`].draw}
                                                onChange={(e) => handleStatsChange(number, 'draw', parseInt(e.target.value))}
                                                placeholder="Draws"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>Draws</label>
                                        </div>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input

                                                value={nums[`num${number}`].totalTime}
                                                onChange={(e) => handleStatsChange(number, 'totalTime', e.target.value)}
                                                placeholder="totalTime"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>TOTAL FIGHT TIME</label>
                                        </div>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input
                                                type="number"
                                                value={nums[`num${number}`].points}
                                                onChange={(e) => handleStatsChange(number, 'points', parseInt(e.target.value))}
                                                placeholder="Points"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>Points</label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                        }

                        <button
                            type="submit"
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                            }}
                        >
                            {t("Submit")}
                        </button>
                    </form>
                ) : null}

                <button // PREVIOUS RANKING
                    onClick={() => setPreviousRankingsVisible(!previousRankingsVisible)}
                    style={{
                        padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                        color: previousRankingsVisible ? 'white' : 'black', borderRadius: 50, marginTop: '50px', cursor: 'pointer',
                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                        height: 50, width: '45%', fontSize: 15, backgroundColor: previousRankingsVisible ? '#ff3030' : 'white', marginLeft: 20
                    }}>
                    Previous Ranking
                </button>

                {previousRankingsVisible ?
                    <div style={{
                        marginTop: '100px', width: '100%', alignItems: 'center', justifyContent: 'center', marginLeft: '70px'
                    }}>
                        <PreviousRankings />
                    </div>
                    : null}
            </div>
        </div >
    );
}

export function PreviousRankings() {
    const { t } = useTranslation();
    const [fighterData, setFighterData] = useState([]);
    const [id, setId] = useState('');
    const [division, setDivision] = useState("");
    const [gender, setGender] = useState("");
    const [lastName, setLastName] = useState("");
    const [org, setOrg] = useState("");
    const [rankings, setRankings] = useState([]);
    const [rankingReady, setRankingReady] = useState(false);
    const [champ, setChamp] = useState("");
    const [interim, setInterim] = useState("");


    const [nums, setNums] = useState({
        num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },

    });

    const [orgs, setOrgs] = useState([])


    const genders = [{ value: "men", label: "MEN" }, { value: "women", label: "WOMEN" }];


    const divisions = gender === "men" ? [{ value: "1heavy", label: "HEAVYWEIGHT" },
    { value: "2lightheavy", label: "LIGHT-HEAVYWEIGHT" },
    { value: "3middle", label: "MIDDLEWEIGHT" },
    { value: "4welter", label: "WELTERWEIGHT" },
    { value: "5light", label: "LIGHTWEIGHT" },
    { value: "6feather", label: "FEATHERWEIGHT" },
    { value: "7bantam", label: "BANTAMWEIGHT" },
    { value: "8fly", label: "FLYWEIGHT" }] :
        [{ value: "6feather", label: "WOMEN FEATHERWEIGHT" },
        { value: "7bantam", label: "WOMEN BANTAMWEIGHT" },
        { value: "8fly", label: "WOMEN FLYWEIGHT" },
        { value: "9straw", label: "WOMEN STRAWWEIGHT" },
        { value: "10atom", label: "WOMEN ATOMWEIGHT" },];


    const navigate = useNavigate();

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    const [formulaireVisible, setFormulaireVisible] = useState(false);

    /////////// RECHERCHE
    ////////////// NOM COMBATTANTS //////////////

    const [filteredFighters, setFilteredFighters] = useState([]);

    const [searchChamp, setSearchChamp] = useState('');
    const [searchInterim, setSearchInterim] = useState('');

    // Créez une instance de l'index Algolia

    const fetchFighters = async (search, orga, gender, attributesToRetrieve = ['id']) => {
        const functions = getFunctions(app);
        const searchFighters = httpsCallable(functions, 'searchFighters');

        let fetchedData = [];

        try {
            // Créez une requête de recherche Algolia sans filtres
            const result = await searchFighters({
                search,
                orga,
                gender,
                attributesToRetrieve
            });

            // Ajoutez tous les résultats de recherche à fetchedData
            if (result.data && Array.isArray(result.data.hits)) {
                fetchedData = result.data.hits.map(hit => ({
                    id: hit.id,
                    ...hit
                }));
            } else {
                console.log('No results from Algolia search or data structure is unexpected.');
            }
        } catch (error) {
            console.error('Error loading favorite fighters data:', error);
        }

        return fetchedData;
    };
    //////////// TEST /////////////
    const [searches, setSearches] = useState({
        search1: '',
        search2: '',
        search3: '',
        search4: '',
        search5: '',
        search6: '',
        search7: '',
        search8: '',
        search9: '',
        search10: '',
        search11: '',
        search12: '',
        search13: '',
        search14: '',
        search15: '',

    });

    const handleSearchChange = (number, value) => {
        setSearches(prevSearches => ({
            ...prevSearches,
            [`search${number}`]: value
        }));
    };
    const handleNumChange = (number, value) => {
        setNums(prevNums => ({
            ...prevNums,
            [`num${number}`]: {
                ...prevNums[`num${number}`],
                id: value

            }

        }));

    };

    const handleStatsChange = (number, field, value) => {
        setNums(prevNums => ({
            ...prevNums,
            [`num${number}`]: {
                ...prevNums[`num${number}`],
                [field]: value
            }
        }));
    };



    useEffect(() => {
        // Ici, vous pouvez itérer sur chaque clé de l'objet searches
        Object.entries(searches).forEach(([key, value]) => {
            if (value) {
                const fetchFightersData = async () => {
                    const fetchedData = await fetchFighters(value, org, gender);
                    const results = fetchedData.filter(fighter =>
                        fighter.id && fighter.id.toLowerCase().includes(value.toLowerCase())
                    );

                    setFilteredFighters(results);
                };

                fetchFightersData();
            }
        });
    }, [searches]);

    /////////////////////////////

    useEffect(() => { // CHAMP
        if (searchChamp) {
            const fetchFightersData = async () => {
                const fetchedData = await fetchFighters(searchChamp, org, gender);

                // After fetching, filter the data based on the search term
                const results = fetchedData.filter(fighter =>
                    fighter.id && fighter.id.toLowerCase().includes(searchChamp.toLowerCase())
                );


                setFilteredFighters(results);
            };

            fetchFightersData();
        }
    }, [searchChamp]);

    useEffect(() => { // INTERIM
        if (searchInterim) {
            const fetchFightersData = async () => {
                const fetchedData = await fetchFighters(searchInterim, org, gender);

                // After fetching, filter the data based on the search term
                const results = fetchedData.filter(fighter =>
                    fighter.id && fighter.id.toLowerCase().includes(searchInterim.toLowerCase())
                );


                setFilteredFighters(results);
            };

            fetchFightersData();
        }
    }, [searchInterim]);

    // Charger les rankings
    useEffect(() => {
        const fetchRankings = async () => {
            if (org && gender && division) {
                const docRef = doc(db, "fightData", "organisations", org, "rankings", gender, division, 'previousRanking', division);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setRankings(data);
                } else {
                    setRankings([]);
                }
            }

            setRankingReady(true);
        };
        setSearchChamp(''); setSearchInterim('');
        setSearches({
            search1: '', search2: '', search3: '', search4: '', search5: '', search6: '', search7: '',
            search8: '', search9: '', search10: '', search11: '', search12: '', search13: '', search14: '', search15: ''
        })
        setChamp('');
        setInterim('');
        setNums({
            num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
            num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
        })
        setRankingReady(false);
        fetchRankings();
    }, [org, gender, division]);


    useEffect(() => { //récupération rankings
        if (rankings && Object.keys(rankings).length > 0 && rankingReady) {

            // Créer un tableau pour stocker les IDs des documents
            const ids = [];

            const fetchDocIds = async () => {
                // Liste des clés que vous voulez parcourir
                const keys = [...Array(15).keys()].map(num => (num + 1).toString()).concat(['interim', 'champ']);

                for (let key of keys) {
                    if (rankings[key]) {
                        const docSnap = await getDoc(rankings[key]);

                        if (docSnap.exists()) {
                            ids.push({ [key]: docSnap.id }); // Ajoutez l'ID du document avec sa clé respective
                        }
                    }
                }

                // Récupération des IDs en fonction de leurs clés
                const champId = ids.find(idObj => idObj.champ)?.champ;
                const interimId = ids.find(idObj => idObj.interim)?.interim;
                const num1Id = ids.find(idObj => idObj["1"])?.["1"]; const num9Id = ids.find(idObj => idObj["9"])?.["9"];
                const num2Id = ids.find(idObj => idObj["2"])?.["2"]; const num10Id = ids.find(idObj => idObj["10"])?.["10"];
                const num3Id = ids.find(idObj => idObj["3"])?.["3"]; const num11Id = ids.find(idObj => idObj["11"])?.["11"];
                const num4Id = ids.find(idObj => idObj["4"])?.["4"]; const num12Id = ids.find(idObj => idObj["12"])?.["12"];
                const num5Id = ids.find(idObj => idObj["5"])?.["5"]; const num13Id = ids.find(idObj => idObj["13"])?.["13"];
                const num6Id = ids.find(idObj => idObj["6"])?.["6"]; const num14Id = ids.find(idObj => idObj["14"])?.["14"];
                const num7Id = ids.find(idObj => idObj["7"])?.["7"]; const num15Id = ids.find(idObj => idObj["15"])?.["15"];
                const num8Id = ids.find(idObj => idObj["8"])?.["8"];

                // Mise à jour des états



                setChamp(champId);
                setInterim(interimId);

                if (org !== 'PFL') {

                    setNums(prevNums => ({ ...prevNums, [[`num1`]]: { id: num1Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num2`]]: { id: num2Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num3`]]: { id: num3Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num4`]]: { id: num4Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num5`]]: { id: num5Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num6`]]: { id: num6Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num7`]]: { id: num7Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num8`]]: { id: num8Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num9`]]: { id: num9Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num10`]]: { id: num10Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num11`]]: { id: num11Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num12`]]: { id: num12Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num13`]]: { id: num13Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num14`]]: { id: num14Id } }));
                    setNums(prevNums => ({ ...prevNums, [[`num15`]]: { id: num15Id } }));
                } else {
                    const newNums = {};
                    // Assuming `data` is an object where each key corresponds to `numX` and its value is an object with fighter details
                    Object.keys(rankings).forEach((key, index) => {
                        // Make sure we only work within the limits of existing `nums` keys

                        const fighterData = rankings[key];

                        if (fighterData.fighterRef && typeof fighterData.fighterRef === 'object' && fighterData.fighterRef.id) {
                            newNums[`num${index + 1}`] = {
                                id: fighterData.fighterRef.id,  // Assuming this needs to be a reference ID or similar
                                win: fighterData.win || 0,
                                lose: fighterData.lose || 0,
                                draw: fighterData.draw || 0,
                                points: fighterData.points || 0,
                                totalTime: fighterData.totalTime || '',
                            };
                        }
                    });
                    setNums(newNums);
                }

            };

            fetchDocIds();
        }
    }, [rankings, rankingReady]);


    /// formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (org !== 'PFL') {
            try {
                const articleRef = doc(db, "fightData", "organisations", org, "rankings", gender, division, 'previousRanking', division);

                const updateObject = {

                    ...(nums[`num1`].id !== "" && nums[`num1`].id && { ["1"]: doc(db, "fighterData", nums[`num1`].id) }),
                    ...(nums[`num2`].id !== "" && nums[`num2`].id && { ["2"]: doc(db, "fighterData", nums[`num2`].id) }),
                    ...(nums[`num3`].id !== "" && nums[`num3`].id && { ["3"]: doc(db, "fighterData", nums[`num3`].id) }),
                    ...(nums[`num4`].id !== "" && nums[`num4`].id && { ["4"]: doc(db, "fighterData", nums[`num4`].id) }),
                    ...(nums[`num5`].id !== "" && nums[`num5`].id && { ["5"]: doc(db, "fighterData", nums[`num5`].id) }),
                    ...(nums[`num6`].id !== "" && nums[`num6`].id && { ["6"]: doc(db, "fighterData", nums[`num6`].id) }),
                    ...(nums[`num7`].id !== "" && nums[`num7`].id && { ["7"]: doc(db, "fighterData", nums[`num7`].id) }),
                    ...(nums[`num8`].id !== "" && nums[`num8`].id && { ["8"]: doc(db, "fighterData", nums[`num8`].id) }),
                    ...(nums[`num9`].id !== "" && nums[`num9`].id && { ["9"]: doc(db, "fighterData", nums[`num9`].id) }),
                    ...(nums[`num10`].id !== "" && nums[`num10`].id && { ["10"]: doc(db, "fighterData", nums[`num10`].id) }),
                    ...(nums[`num11`].id !== "" && nums[`num11`].id && { ["11"]: doc(db, "fighterData", nums[`num11`].id) }),
                    ...(nums[`num12`].id !== "" && nums[`num12`].id && { ["12"]: doc(db, "fighterData", nums[`num12`].id) }),
                    ...(nums[`num13`].id !== "" && nums[`num13`].id && { ["13"]: doc(db, "fighterData", nums[`num13`].id) }),
                    ...(nums[`num14`].id !== "" && nums[`num14`].id && { ["14"]: doc(db, "fighterData", nums[`num14`].id) }),
                    ...(nums[`num15`].id !== "" && nums[`num15`].id && { ["15"]: doc(db, "fighterData", nums[`num15`].id) })
                };

                await setDoc(articleRef, updateObject);
                setDivision('');
                setSearchChamp(''); setSearchInterim('');
                setSearches({
                    search1: '', search2: '', search3: '', search4: '', search5: '', search6: '', search7: '',
                    search8: '', search9: '', search10: '', search11: '', search12: '', search13: '', search14: '', search15: ''
                })
                setChamp('');
                setInterim('');
                setNums({
                    num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                })
                setFormulaireVisible(false);

                alert("Classement soumis avec succès !");
            } catch (error) {
                console.error("Error adding document: ", error);
            }

        } else {
            try {
                const articleRef = doc(db, "fightData", "organisations", org, "rankings", gender, division, 'previousRanking', division);

                const updateObject = {

                    ...(nums[`num1`] && nums[`num1`].id && nums[`num1`].id !== "" && {
                        [nums[`num1`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num1`].id),
                            win: nums[`num1`].win,
                            lose: nums[`num1`].lose,
                            draw: nums[`num1`].draw,
                            points: nums[`num1`].points,
                            totalTime: nums[`num1`].totalTime,
                        }
                    }),
                    ...(nums[`num2`] && nums[`num2`].id && nums[`num2`].id !== "" && {
                        [nums[`num2`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num2`].id),
                            win: nums[`num2`].win,
                            lose: nums[`num2`].lose,
                            draw: nums[`num2`].draw,
                            points: nums[`num2`].points,
                            totalTime: nums[`num2`].totalTime,
                        }
                    }),
                    ...(nums[`num3`] && nums[`num3`].id && nums[`num3`].id !== "" && {
                        [nums[`num3`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num3`].id),
                            win: nums[`num3`].win,
                            lose: nums[`num3`].lose,
                            draw: nums[`num3`].draw,
                            points: nums[`num3`].points,
                            totalTime: nums[`num3`].totalTime,
                        }
                    }),
                    ...(nums[`num4`] && nums[`num4`].id && nums[`num4`].id !== "" && {
                        [nums[`num4`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num4`].id),
                            win: nums[`num4`].win,
                            lose: nums[`num4`].lose,
                            draw: nums[`num4`].draw,
                            points: nums[`num4`].points,
                            totalTime: nums[`num4`].totalTime,
                        }
                    }),
                    ...(nums[`num5`] && nums[`num5`].id && nums[`num5`].id !== "" && {
                        [nums[`num5`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num5`].id),
                            win: nums[`num5`].win,
                            lose: nums[`num5`].lose,
                            draw: nums[`num5`].draw,
                            points: nums[`num5`].points,
                            totalTime: nums[`num5`].totalTime,
                        }
                    }),
                    ...(nums[`num6`] && nums[`num6`].id && nums[`num6`].id !== "" && {
                        [nums[`num6`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num6`].id),
                            win: nums[`num6`].win,
                            lose: nums[`num6`].lose,
                            draw: nums[`num6`].draw,
                            points: nums[`num6`].points,
                            totalTime: nums[`num6`].totalTime,
                        }
                    }),
                    ...(nums[`num7`] && nums[`num7`].id && nums[`num7`].id !== "" && {
                        [nums[`num7`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num7`].id),
                            win: nums[`num7`].win,
                            lose: nums[`num7`].lose,
                            draw: nums[`num7`].draw,
                            points: nums[`num7`].points,
                            totalTime: nums[`num7`].totalTime,
                        }
                    }),
                    ...(nums[`num8`] && nums[`num8`].id && nums[`num8`].id !== "" && {
                        [nums[`num8`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num8`].id),
                            win: nums[`num8`].win,
                            lose: nums[`num8`].lose,
                            draw: nums[`num8`].draw,
                            points: nums[`num8`].points,
                            totalTime: nums[`num8`].totalTime,
                        }
                    }),
                    ...(nums[`num9`] && nums[`num9`].id && nums[`num9`].id !== "" && {
                        [nums[`num9`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num9`].id),
                            win: nums[`num9`].win,
                            lose: nums[`num9`].lose,
                            draw: nums[`num9`].draw,
                            points: nums[`num9`].points,
                            totalTime: nums[`num9`].totalTime,
                        }
                    }),
                    ...(nums[`num10`] && nums[`num10`].id && nums[`num10`].id !== "" && {
                        [nums[`num10`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num10`].id),
                            win: nums[`num10`].win,
                            lose: nums[`num10`].lose,
                            draw: nums[`num10`].draw,
                            points: nums[`num10`].points,
                            totalTime: nums[`num10`].totalTime,
                        }
                    }),
                    ...(nums[`num11`] && nums[`num11`].id && nums[`num11`].id !== "" && {
                        [nums[`num11`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num11`].id),
                            win: nums[`num11`].win,
                            lose: nums[`num11`].lose,
                            draw: nums[`num11`].draw,
                            points: nums[`num11`].points,
                            totalTime: nums[`num11`].totalTime,
                        }
                    }),
                    ...(nums[`num12`] && nums[`num12`].id && nums[`num12`].id !== "" && {
                        [nums[`num12`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num12`].id),
                            win: nums[`num12`].win,
                            lose: nums[`num12`].lose,
                            draw: nums[`num12`].draw,
                            points: nums[`num12`].points,
                            totalTime: nums[`num12`].totalTime,
                        }
                    }),
                    ...(nums[`num13`] && nums[`num13`].id && nums[`num13`].id !== "" && {
                        [nums[`num13`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num13`].id),
                            win: nums[`num13`].win,
                            lose: nums[`num13`].lose,
                            draw: nums[`num13`].draw,
                            points: nums[`num13`].points,
                            totalTime: nums[`num13`].totalTime,
                        }
                    }),
                    ...(nums[`num14`] && nums[`num14`].id && nums[`num14`].id !== "" && {
                        [nums[`num14`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num14`].id),
                            win: nums[`num14`].win,
                            lose: nums[`num14`].lose,
                            draw: nums[`num14`].draw,
                            points: nums[`num14`].points,
                            totalTime: nums[`num14`].totalTime,
                        }
                    }),
                    ...(nums[`num15`] && nums[`num15`].id && nums[`num15`].id !== "" && {
                        [nums[`num15`].id]: {
                            fighterRef: doc(db, "fighterData", nums[`num15`].id),
                            win: nums[`num15`].win,
                            lose: nums[`num15`].lose,
                            draw: nums[`num15`].draw,
                            points: nums[`num15`].points,
                            totalTime: nums[`num15`].totalTime,
                        }
                    })
                };

                await setDoc(articleRef, updateObject);
                setOrg(''); setGender(''); setDivision('');
                setSearchChamp(''); setSearchInterim('');
                setSearches({
                    search1: '', search2: '', search3: '', search4: '', search5: '', search6: '', search7: '',
                    search8: '', search9: '', search10: '', search11: '', search12: '', search13: '', search14: '', search15: ''
                })
                setChamp('');
                setInterim('');
                setNums({
                    num1: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num2: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num3: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num4: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num5: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num6: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num7: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num8: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num9: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num10: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num11: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num12: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                    num13: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num14: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' }, num15: { id: '', win: 0, lose: 0, draw: 0, points: 0, totalTime: '' },
                })
                setFormulaireVisible(false);

                alert("Classement soumis avec succès !");
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        }


    };


    /////////// BOUTON ////////////
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };
    ////////////
    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>RECHERCHER UN PRÉCÉDENT CLASSEMENT</h2>

                <div style={{ backgroundColor: '#353535', padding: '20px', borderRadius: 10 }}>
                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white', fontWeight: 'bold' }}>Organisation :</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // Genre
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white', fontWeight: 'bold' }}>{t("Gender")} :</label>
                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a gender")}</option>
                            {genders.map((gender, index) => (
                                <option key={index} value={gender.value}>
                                    {gender.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // division
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white', fontWeight: 'bold' }}>{t("Division")} :</label>
                        <select
                            value={division}
                            onChange={(e) => {
                                setDivision(e.target.value); if (e.target.value !== "") {
                                    setFormulaireVisible(true);
                                }
                            }}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a division")}</option>
                            {divisions.map((division, index) => (
                                <option key={index} value={division.value}>
                                    {division.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {formulaireVisible ? (
                    <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>

                        {Array.from({ length: 15 }, (_, index) => index + 1).map(number => (
                            <div style={{
                                width: '100%', marginBottom: '10px'
                            }}>
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                    {org !== 'PFL' ?
                                        <label style={{ marginRight: 5 }}>{number}</label>
                                        : null}

                                    <input
                                        type="text"
                                        value={searches[`search${number}`]}
                                        onChange={(e) => handleSearchChange(number, e.target.value)}
                                        placeholder={nums[`num${number}`] && nums[`num${number}`].id ? nums[`num${number}`].id : "Search a fighter..."}
                                        className={nums[`num${number}`] && nums[`num${number}`].id ? "custom-placeholder-input" : null}
                                        style={{
                                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none',
                                        }} />

                                    {nums[`num${number}`] ?
                                        <button type="button" onClick={() => handleNumChange(number, '')}
                                            style={{
                                                padding: '10px 10px', border: 'none', backgroundColor: '#383838', marginLeft: '10px',
                                                color: 'white', borderRadius: '8px', cursor: 'pointer', marginRight: '10px',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                            }}>
                                            <FaTrash />
                                        </button>
                                        : null}

                                    {searches[`search${number}`] && (
                                        <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                                            {filteredFighters.map((fighter, index) => (
                                                <div
                                                    key={index}
                                                    style={{ padding: '10px', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleNumChange(number, fighter.id);
                                                        setFilteredFighters([]);
                                                        handleSearchChange(number, ''); // Vider le champ de recherche lors de la sélection
                                                    }}
                                                >
                                                    {fighter.id}
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </div>

                                {nums[`num${number}`] && nums[`num${number}`].id && org === 'PFL' && (
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input
                                                type="number"
                                                value={nums[`num${number}`].win}
                                                onChange={(e) => handleStatsChange(number, 'win', parseInt(e.target.value))}
                                                placeholder="Wins"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>Wins</label>
                                        </div>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input
                                                type="number"
                                                value={nums[`num${number}`].lose}
                                                onChange={(e) => handleStatsChange(number, 'lose', parseInt(e.target.value))}
                                                placeholder="Losses"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>Losses</label>
                                        </div>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input
                                                type="number"
                                                value={nums[`num${number}`].draw}
                                                onChange={(e) => handleStatsChange(number, 'draw', parseInt(e.target.value))}
                                                placeholder="Draws"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>Draws</label>
                                        </div>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input

                                                value={nums[`num${number}`].totalTime}
                                                onChange={(e) => handleStatsChange(number, 'totalTime', e.target.value)}
                                                placeholder="totalTime"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>TOTAL FIGHT TIME</label>
                                        </div>

                                        <div style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}>
                                            <input
                                                type="number"
                                                value={nums[`num${number}`].points}
                                                onChange={(e) => handleStatsChange(number, 'points', parseInt(e.target.value))}
                                                placeholder="Points"
                                                style={{
                                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none',
                                                }}
                                            />
                                            <label style={{}}>Points</label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                        }

                        <button
                            type="submit"
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                            }}
                        >
                            {t("Submit")}
                        </button>
                    </form>
                ) : null}

            </div>
        </div >

    );
}