import React, { useState, useEffect, } from "react";
import { getDoc, getDocs, doc, setDoc, updateDoc, onSnapshot, collection, addDoc, Timestamp, deleteDoc } from 'firebase/firestore';
import { useNavigate, useParams, Link, useHistory, useNavigation } from 'react-router-dom';
import { getAuth, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { FaUser, FaArrowLeft, FaTv } from 'react-icons/fa';
import { db, app } from "../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { Menu } from "./AccueilAdmin";
import Logo from '../../assets/logo/logo-horizontale-v2.png';
import { useTranslation } from 'react-i18next';


export function Event() {
    const { t } = useTranslation();
    const [displayEvent, setDisplayEvent] = useState(0); // 1 = combat passé, 2 = combat à venir

    /////// BOUTON
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };
    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#fcfaf5"
        }}>
            <div style={{
                position: 'absolute', top: '0', height: '60px', width: '100%', display: 'flex',
                justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', padding: '0 20px'
            }}>
                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <img src={Logo} alt="Logo" style={{ height: '250px' }} />
                    <h2 style={{ margin: 0, color: 'white', fontSize: '1.2em' }}>EVENT</h2>
                </div>

                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <button onClick={toggleMenu} style={{
                        padding: '8px 16px', border: 'none', backgroundColor: 'white', color: '#ff3030',
                        borderRadius: '25px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginRight: '10px'
                    }}>
                        {isMenuOpen ? 'Fermer le menu' : 'Ouvrir le menu'}
                    </button>

                    <button onClick={handleLogout} style={{
                        background: 'none', border: '1px solid white', color: 'white', padding: '8px 16px',
                        borderRadius: '25px', cursor: 'pointer', fontWeight: 'bolder'
                    }}>
                        Logout
                    </button>
                </div>
            </div>


            {isMenuOpen && (
                <Menu />
            )}



            <div style={{ // DONNÉE FIGHTER
                marginTop: '100px', width: '60%', alignItems: 'center', justifyContent: 'center',
            }}>

                <div style={{ // BOUTONS
                    flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
                }}>
                    <button // 
                        onClick={() => setDisplayEvent(1)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: displayEvent === 1 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '45%', fontSize: 15, backgroundColor: displayEvent === 1 ? '#ff3030' : 'white',

                        }}>
                        ADD EVENT
                    </button>

                    <button // 
                        onClick={() => setDisplayEvent(2)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: displayEvent === 2 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '45%', fontSize: 15, backgroundColor: displayEvent === 2 ? '#ff3030' : 'white', marginLeft: 20
                        }}>
                        UPDATE EVENT
                    </button>


                    <button // 
                        onClick={() => setDisplayEvent(3)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: displayEvent === 3 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '45%', fontSize: 15, backgroundColor: displayEvent === 3 ? '#ff3030' : 'white', marginLeft: 20
                        }}>
                        ADD TV EVENT
                    </button>

                    <button // 
                        onClick={() => setDisplayEvent(4)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: displayEvent === 4 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '45%', fontSize: 15, backgroundColor: displayEvent === 4 ? '#ff3030' : 'white', marginLeft: 20
                        }}>
                        UPDATE TV EVENT
                    </button>
                </div>

                {displayEvent === 1 ? <SubmitEvent /> : null}
                {displayEvent === 2 ? <UpdateEvent /> : null}
                {displayEvent === 3 ? <SubmitEventTV /> : null}
                {displayEvent === 4 ? <UpdateEventTV /> : null}

            </div>
        </div>


    )
}

export function SubmitEvent() {
    const { t } = useTranslation();
    const [eventName, setEventName] = useState("");
    const [org, setOrg] = useState("");

    const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];

    const timeZones = moment.tz.names();

    const [selectedMonth, setSelectedMonth] = useState('');

    const [selectedEventEndDay, setSelectedEventEndDay] = useState('');

    const [selectedDateDay, setSelectedDateDay] = useState('');
    const [selectedDateHour, setSelectedDateHour] = useState('');
    const [selectedDateMinute, setSelectedDateMinute] = useState('');

    const earlyPrelims = [{ value: true, label: "YES" }, { value: false, label: "NO" }];
    const [ifEarlyPrelims, setIfEarlyPrelims] = useState(false);

    const [selectedEarlyprelimsDay, setSelectedEarlyprelimsDay] = useState(selectedDateDay || '');
    const [selectedEarlyprelimsHour, setSelectedEarlyprelimsHour] = useState('');
    const [selectedEarlyprelimsMinute, setSelectedEarlyprelimsMinute] = useState('');

    const [selectedPrelimsDay, setSelectedPrelimsDay] = useState(selectedDateDay || '');
    const [selectedPrelimsHour, setSelectedPrelimsHour] = useState('');
    const [selectedPrelimsMinute, setSelectedPrelimsMinute] = useState('');

    const [selectedMaincardDay, setSelectedMaincardDay] = useState(selectedDateDay || '');
    const [selectedMaincardHour, setSelectedMaincardHour] = useState('');
    const [selectedMaincardMinute, setSelectedMaincardMinute] = useState('');

    const currentYear = new Date().getFullYear();


    const [selectedYear, setSelectedYear] = useState(currentYear.toString());

    const [orgs, setOrgs] = useState([])

    const [location, setLocation] = useState({
        arena: '',
        link: '',
        place: {
            en: ''
        },
    });

    const navigate = useNavigate();

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    const [eventAlreadyExist, setEventAlreadyExist] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const dateWithTimezone = moment(`${selectedYear}-${selectedMonth}-${selectedDateDay} ${selectedDateHour}:${selectedDateMinute}`); // Utilisez le bon format de date et le bon fuseau horaire
            const earlyprelimsTimezone = moment(`${selectedYear}-${selectedMonth}-${selectedEarlyprelimsDay} ${selectedEarlyprelimsHour}:${selectedEarlyprelimsMinute}`);
            const prelimsTimezone = moment(`${selectedYear}-${selectedMonth}-${selectedPrelimsDay} ${selectedPrelimsHour}:${selectedPrelimsMinute}`);
            const maincardTimezone = moment(`${selectedYear}-${selectedMonth}-${selectedMaincardDay} ${selectedMaincardHour}:${selectedMaincardMinute}`);

            // Créez un nouvel objet moment à partir de maincardTimezone et ajoutez un jour
            const eventEndTimeTimeZone = moment(maincardTimezone).add(1, 'days');
            // Utilisez eventEndTimeTimeZone pour obtenir l'année, le mois et le jour
            const eventEndYear = eventEndTimeTimeZone.year();
            const eventEndMonth = eventEndTimeTimeZone.month() + 1; // month() retourne un mois de 0 à 11, donc ajoutez 1 pour le format habituel
            const eventEndDay = eventEndTimeTimeZone.date();

            const eventEndTimeFormatted = moment(`${eventEndYear}-${eventEndMonth}-${eventEndDay} ${selectedMaincardHour}:${selectedMaincardMinute}`);


            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, `${org} ${selectedDateDay}.${selectedMonth}`);

            const docSnap1 = await getDoc(articleRef);

            if (docSnap1?.exists()) {
                setEventAlreadyExist(true);
                alert("ERROR: EVENT ALREADY EXIST ");
                return;
            }



            await setDoc(articleRef, {
                date: Timestamp.fromMillis(dateWithTimezone.valueOf()),
                earlyprelimstime: Timestamp.fromMillis(earlyprelimsTimezone.valueOf()),
                eventEndTime: Timestamp.fromMillis(eventEndTimeFormatted.valueOf()),
                eventStartTime: Timestamp.fromMillis(dateWithTimezone.valueOf()),
                location,
                maincardtime: Timestamp.fromMillis(maincardTimezone.valueOf()),
                name: `${org} ${selectedDateDay}.${selectedMonth}`,
                displayEventName: `${org} ${eventName}`,
                prelimstime: Timestamp.fromMillis(prelimsTimezone.valueOf()),
                ifEarlyPrelims

            });

            const orgaDataRef = doc(db, "fightData", "organisations", org, 'events');
            const docSnapshot = await getDoc(orgaDataRef);


            if (docSnapshot.exists()) {
                let data = docSnapshot.data();
                // Assurez-vous que data.years est un tableau
                if (!Array.isArray(data.years)) {
                    data.years = [];
                }

                // Vérifiez si selectedYear est dans le tableau, sinon ajoutez-le
                if (!data.years.includes(selectedYear)) {
                    data.years.push(selectedYear);
                    await updateDoc(orgaDataRef, { years: data.years });
                }
            } else {
                // Si le document n'existe pas, créez-le avec years initialisé avec selectedYear
                await setDoc(orgaDataRef, { years: [selectedYear] });
            }
            setSelectedMaincardDay(""); setSelectedPrelimsDay(""); setSelectedEarlyprelimsDay(""); setEventAlreadyExist(false);
            setEventName(""); setSelectedDateDay(""); setSelectedMonth(""); setSelectedDateHour(""); setSelectedDateMinute("");
            setSelectedEarlyprelimsHour(""); setSelectedEarlyprelimsMinute(""); setSelectedPrelimsHour(""); setSelectedPrelimsMinute("");
            setSelectedMaincardHour(""); setSelectedMaincardMinute(""); setLocation({
                arena: '',
                link: '',
                place: {
                    en: ''
                },
            });
            alert("Event soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };



    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "place") {
            setLocation({
                ...location,
                place: {
                    ...location.place,
                    en: value
                }
            });
        } else {
            setLocation({
                ...location,
                [name]: value
            });
        }
    };




    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Subit an event")}</h2>


                <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    {eventAlreadyExist ?
                        <label style={{ color: 'red', fontWeight: 'bold', marginVertical: '10px' }}>THE EVENT ALREADY EXIST</label>
                        : null}

                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Organization :</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // YEARS
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Year")} :</label>
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a year")}</option>
                            {years.map((years, index) => (
                                <option key={index} value={years}>
                                    {years}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // EVENTNOM
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Name of the event")} :</label>

                        <div style={{ // nom
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{org} : </label>
                            <input
                                type="text"
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                                required
                                style={{
                                    width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }}
                            />
                        </div>
                    </div>



                    <div style={{ // DATE timestamp
                        width: '100%',
                    }}>
                        <label>Date event :</label>

                        <div style={{
                            width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                        }}>

                            <label>{selectedYear}</label>

                            <div style={{ // MOIS
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10
                            }}>
                                <input
                                    type="text"
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    required
                                    style={{
                                        width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Month")}</label>

                            </div>



                            <div style={{ // jour
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                            }}>
                                <input
                                    type="text"
                                    value={selectedDateDay}
                                    onChange={(e) => setSelectedDateDay(e.target.value)}
                                    required
                                    style={{
                                        width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Day")}</label>
                            </div>



                            <div style={{ // HEURE
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 30,
                            }}>
                                <input
                                    type="text"
                                    value={selectedDateHour}
                                    onChange={(e) => setSelectedDateHour(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Hour")}</label>
                            </div>

                            <div style={{ // MINUTE
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                            }}>
                                <input
                                    type="text"
                                    value={selectedDateMinute}
                                    onChange={(e) => setSelectedDateMinute(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Minute")}</label>
                            </div>
                        </div>


                    </div>



                    <div style={{ // EARLY PRELIMS ?
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>EARLY PRELIMS ?</label>
                        <select
                            value={ifEarlyPrelims}
                            onChange={(e) => setIfEarlyPrelims(e.target.value === 'true')}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">YES or NO</option>
                            {earlyPrelims.map((earlyPrelimsOption, index) => (
                                <option key={index} value={earlyPrelimsOption.value}>
                                    {earlyPrelimsOption.label}
                                </option>
                            ))}
                        </select>

                    </div>

                    {ifEarlyPrelims ?
                        <div style={{ // Early prelims time timestamp
                            width: '100%',
                        }}>
                            <label>IF (Early prelims time) :</label>

                            <div style={{
                                width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                            }}>
                                <div style={{ // jour
                                    marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                }}>
                                    <input
                                        type="text"
                                        value={selectedEarlyprelimsDay}
                                        onChange={(e) => setSelectedEarlyprelimsDay(e.target.value)}
                                        required
                                        style={{
                                            width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ fontSize: 15, }}>{t("Day")}</label>
                                </div>

                                <div style={{ // HEURE
                                    marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                }}>
                                    <input
                                        type="text"
                                        value={selectedEarlyprelimsHour}
                                        onChange={(e) => setSelectedEarlyprelimsHour(e.target.value)}
                                        required
                                        style={{
                                            width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ fontSize: 15, }}>{t("Hour")}</label>
                                </div>

                                <div style={{ // MINUTE
                                    marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                }}>
                                    <input
                                        type="text"
                                        value={selectedEarlyprelimsMinute}
                                        onChange={(e) => setSelectedEarlyprelimsMinute(e.target.value)}
                                        required
                                        style={{
                                            width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ fontSize: 15, }}>{t("Minute")}</label>
                                </div>
                            </div>


                        </div>
                        : null}

                    <div style={{ //  prelims time timestamp
                        width: '100%',
                    }}>
                        <label>Prelims time :</label>

                        <div style={{
                            width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                        }}>

                            <div style={{ // jour
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                            }}>
                                <input
                                    type="text"
                                    value={selectedPrelimsDay}
                                    onChange={(e) => setSelectedPrelimsDay(e.target.value)}
                                    required
                                    style={{
                                        width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Day")}</label>
                            </div>

                            <div style={{ // HEURE
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>
                                <input
                                    type="text"
                                    value={selectedPrelimsHour}
                                    onChange={(e) => setSelectedPrelimsHour(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Hour")}</label>
                            </div>

                            <div style={{ // MINUTE
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                            }}>
                                <input
                                    type="text"
                                    value={selectedPrelimsMinute}
                                    onChange={(e) => setSelectedPrelimsMinute(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Minute")}</label>
                            </div>
                        </div>


                    </div>

                    <div style={{ //  Main card time timestamp
                        width: '100%',
                    }}>
                        <label>Main card time :</label>

                        <div style={{
                            width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                        }}>

                            <div style={{ // jour
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                            }}>
                                <input
                                    type="text"
                                    value={selectedMaincardDay}
                                    onChange={(e) => setSelectedMaincardDay(e.target.value)}
                                    required
                                    style={{
                                        width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Day")}</label>
                            </div>

                            <div style={{ // HEURE
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>
                                <input
                                    type="text"
                                    value={selectedMaincardHour}
                                    onChange={(e) => setSelectedMaincardHour(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Hour")}</label>
                            </div>

                            <div style={{ // MINUTE
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                            }}>
                                <input
                                    type="text"
                                    value={selectedMaincardMinute}
                                    onChange={(e) => setSelectedMaincardMinute(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Minute")}</label>
                            </div>
                        </div>


                    </div>

                    <div style={{ // location
                        width: '100%',
                    }}>
                        <label>location :</label>

                        <div style={{ // place
                            marginBottom: '10px', flexDirection: 'column', display: 'flex',
                        }}>
                            <input
                                type="text"
                                name="place"
                                value={location?.place?.en}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                            <label style={{ fontSize: 15, }}>location</label>

                        </div>

                        <div style={{ // arena
                            marginBottom: '10px', flexDirection: 'column', display: 'flex',
                        }}>
                            <input
                                type="text"
                                name="arena"
                                value={location.arena}
                                onChange={handleChange}

                                style={{
                                    width: '100%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                            <label style={{ fontSize: 15, }}>Arena</label>

                        </div>

                        <div style={{ // link
                            marginBottom: '10px', flexDirection: 'column', display: 'flex',
                        }}>
                            <input
                                type="text"
                                name="link"
                                value={location.link}
                                onChange={handleChange}
                                style={{
                                    width: '100%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                            <label style={{ fontSize: 15, }}>{t("ticketing link")}</label>

                        </div>

                    </div>

                    <button
                        type="submit"
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                            color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                        }}
                    >
                        {t("Submit")}
                    </button>
                </form>
            </div>
        </div>

    );
}


export function UpdateEvent() {
    const { t } = useTranslation();
    const [eventName, setEventName] = useState("");
    const [displayEventName, setDisplayEventName] = useState("");
    const [id, setId] = useState("");
    const [org, setOrg] = useState("");
    const [orgs, setOrgs] = useState([])
    const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
    const timeZones = moment.tz.names();
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedEventEndDay, setSelectedEventEndDay] = useState('');
    const [selectedDateDay, setSelectedDateDay] = useState('');
    const [selectedDateHour, setSelectedDateHour] = useState('');
    const [selectedDateMinute, setSelectedDateMinute] = useState('');
    const earlyPrelims = [{ value: true, label: "YES" }, { value: false, label: "NO" }];
    const [ifEarlyPrelims, setIfEarlyPrelims] = useState(false);

    const [selectedEarlyprelimsDay, setSelectedEarlyprelimsDay] = useState(selectedDateDay || '');
    const [selectedEarlyprelimsHour, setSelectedEarlyprelimsHour] = useState('');
    const [selectedEarlyprelimsMinute, setSelectedEarlyprelimsMinute] = useState('');

    const [selectedPrelimsDay, setSelectedPrelimsDay] = useState(selectedDateDay || '');
    const [selectedPrelimsHour, setSelectedPrelimsHour] = useState('');
    const [selectedPrelimsMinute, setSelectedPrelimsMinute] = useState('');

    const [selectedMaincardDay, setSelectedMaincardDay] = useState(selectedDateDay || '');
    const [selectedMaincardHour, setSelectedMaincardHour] = useState('');
    const [selectedMaincardMinute, setSelectedMaincardMinute] = useState('');


    const currentYear = new Date().getFullYear();

    const [selectedYear, setSelectedYear] = useState(currentYear.toString());


    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    ////////////
    const [nombreDevue, setNombredevue] = useState(0);

    useEffect(() => {
        setNombredevue(1)
    }, []);
    //////////




    const [location, setLocation] = useState({
        arena: '',
        link: '',
        place: {
            en: ''
        },
    });

    const navigate = useNavigate();

    //////////// Charger tous les events
    const [events, setEvents] = useState([]);

    const [formulaireVisible, setFormulaireVisible] = useState(false);

    useEffect(() => { // RÉCUPÉRER LES EVENTS

        const fetchEvents = async () => {
            if (selectedYear && org) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear));
                const events = [];
                querySnapshot.forEach((document) => {
                    const data = document.data();
                    events.push({
                        id: document.id,               // Document ID
                        displayEventName: data.displayEventName || document.id  // Fallback to ID if displayEventName doesn't exist
                    });
                });
                setEvents(events);
            };
        };
        console.log('selectedYear', selectedYear)
        fetchEvents();
    }, [selectedYear, org]);

    // Charger les données de l'event sélectionné
    useEffect(() => {
        const fetchEventData = async () => {
            if (selectedYear && org && eventName) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {

                    // Ajout de l'ID du document aux données récupérées
                    const data = { ...docSnap.data(), id: docSnap.id };
                    setEventData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchEventData();

    }, [selectedYear, eventName, org]);

    const [eventData, setEventData] = useState([]);
    const [selectedEventEndHour, setSelectedEventEndHour] = useState('');
    const [selectedEventEndMinute, setSelectedEventEndMinute] = useState('');


    useEffect(() => { // récupération de données
        setId(eventData ? eventData.id : null);
        setEventName(eventData ? eventData.name : null);
        setDisplayEventName(eventData ? eventData.displayEventName : null);
        setIfEarlyPrelims(eventData ? eventData.ifEarlyPrelims : null);

        if (eventData) {
            const newLieu = {
                arena: eventData.location?.arena || '',
                link: eventData.location?.link || '',
                place: {
                    en: eventData.location?.place?.en || ''
                }
            };
            setLocation(newLieu);
        }

        if (eventData && eventData.date) {
            const dateFromFirestore = eventData.date.toDate();


            setSelectedYear(dateFromFirestore.getFullYear().toString());
            setSelectedMonth(dateFromFirestore.getMonth() + 1);
            setSelectedDateDay(dateFromFirestore.getDate());
            setSelectedDateHour(dateFromFirestore.getHours());
            setSelectedDateMinute(dateFromFirestore.getMinutes());
        }


        if (eventData && eventData.earlyprelimstime) {
            const dateFromFirestore = eventData.earlyprelimstime.toDate();
            setSelectedEarlyprelimsDay(dateFromFirestore.getDate());
            setSelectedEarlyprelimsHour(dateFromFirestore.getHours());
            setSelectedEarlyprelimsMinute(dateFromFirestore.getMinutes());
        }

        if (eventData && eventData.prelimstime) {
            const dateFromFirestore = eventData.prelimstime.toDate();
            setSelectedPrelimsDay(dateFromFirestore.getDate());
            setSelectedPrelimsHour(dateFromFirestore.getHours());
            setSelectedPrelimsMinute(dateFromFirestore.getMinutes());
        }

        if (eventData && eventData.maincardtime) {
            const dateFromFirestore = eventData.maincardtime.toDate();
            setSelectedMaincardDay(dateFromFirestore.getDate());
            setSelectedMaincardHour(dateFromFirestore.getHours());
            setSelectedMaincardMinute(dateFromFirestore.getMinutes());
        }

        if (eventData && eventData.eventEndTime) {
            const dateFromFirestore = eventData.eventEndTime.toDate();
            setSelectedEventEndDay(dateFromFirestore.getDate());
            setSelectedEventEndHour(dateFromFirestore.getHours());
            setSelectedEventEndMinute(dateFromFirestore.getMinutes());
        }


    }, [eventData]);

    const handleSubmit = async (e) => { // MODIFIER
        e.preventDefault();

        try {

            const dateWithTimezone = moment(`${selectedYear}-${selectedMonth}-${selectedDateDay} ${selectedDateHour}:${selectedDateMinute}`); // Utilisez le bon format de date et le bon fuseau horaire
            const earlyprelimsTimezone = moment(`${selectedYear}-${selectedMonth}-${selectedEarlyprelimsDay} ${selectedEarlyprelimsHour}:${selectedEarlyprelimsMinute}`);
            const prelimsTimezone = moment(`${selectedYear}-${selectedMonth}-${selectedPrelimsDay} ${selectedPrelimsHour}:${selectedPrelimsMinute}`);
            const maincardTimezone = moment(`${selectedYear}-${selectedMonth}-${selectedMaincardDay} ${selectedMaincardHour}:${selectedMaincardMinute}`);

            // Créez un nouvel objet moment à partir de maincardTimezone et ajoutez un jour
            const eventEndTimeTimeZone = moment(maincardTimezone).add(1, 'days');
            // Utilisez eventEndTimeTimeZone pour obtenir l'année, le mois et le jour
            const eventEndYear = eventEndTimeTimeZone.year();
            const eventEndMonth = eventEndTimeTimeZone.month() + 1; // month() retourne un mois de 0 à 11, donc ajoutez 1 pour le format habituel
            const eventEndDay = eventEndTimeTimeZone.date();

            const eventEndTimeFormatted = moment(`${eventEndYear}-${eventEndMonth}-${eventEndDay} ${selectedMaincardHour}:${selectedMaincardMinute}`);

            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, id);
            await updateDoc(articleRef, {
                date: Timestamp.fromMillis(dateWithTimezone.valueOf()),
                earlyprelimstime: Timestamp.fromMillis(earlyprelimsTimezone.valueOf()),
                eventEndTime: Timestamp.fromMillis(eventEndTimeFormatted.valueOf()),
                eventStartTime: Timestamp.fromMillis(dateWithTimezone.valueOf()),
                location,
                maincardtime: Timestamp.fromMillis(maincardTimezone.valueOf()),
                name: eventName,
                displayEventName: displayEventName,
                prelimstime: Timestamp.fromMillis(prelimsTimezone.valueOf()),
                ifEarlyPrelims,
            });



            setEventName(""); setDisplayEventName(""); setSelectedDateDay(""); setSelectedMonth(""); setSelectedDateHour(""); setSelectedDateMinute("");
            setSelectedEarlyprelimsHour(""); setSelectedEarlyprelimsMinute(""); setSelectedPrelimsHour(""); setSelectedPrelimsMinute("");
            setSelectedMaincardHour(""); setSelectedMaincardMinute(""); setLocation(''); setLocation(''); setLocation(''); setFormulaireVisible(false);

            alert("Event soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };


    const handleDelete = async () => { // SUPPRIMER
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce document ?")) {
            try {
                const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, id);
                const cardCollectionRef = collection(articleRef, 'card'); // Référence de la sous-collection 'card'

                // Récupérer tous les documents de la sous-collection 'card'
                const cardDocsSnapshot = await getDocs(cardCollectionRef);

                // Supprimer chaque sous-document de 'card'
                const deletePromises = cardDocsSnapshot.docs.map(async (cardDoc) => {
                    await deleteDoc(cardDoc.ref);
                });

                // Attendre que toutes les suppressions des sous-documents soient terminées
                await Promise.all(deletePromises);

                // Ensuite, supprimer le document principal
                await deleteDoc(articleRef);

                // Réinitialiser les valeurs et cacher le formulaire
                setEventName(""); setDisplayEventName(""); setSelectedDateDay(""); setSelectedMonth(""); setSelectedDateHour(""); setSelectedDateMinute("");
                setSelectedEarlyprelimsHour(""); setSelectedEarlyprelimsMinute(""); setSelectedPrelimsHour(""); setSelectedPrelimsMinute("");
                setSelectedMaincardHour(""); setSelectedMaincardMinute(""); setLocation(''); setFormulaireVisible(false);

                alert("Document et sous-documents supprimés avec succès !");
            } catch (error) {
                console.error("Error deleting document or sub-documents: ", error);
            }
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "place") {
            setLocation({
                ...location,
                place: {
                    ...location.place,
                    en: value
                }
            });
        } else {
            setLocation({
                ...location,
                [name]: value
            });
        }
    };


    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>
            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("UPDATE an event")}</h2>


                <div style={{ backgroundColor: '#ff3030', padding: '20px', borderRadius: 10 }}>
                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white' }}>Organization :</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // YEARS
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white' }}>{t("Year")} :</label>
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a year")}</option>
                            {years.map((years, index) => (
                                <option key={index} value={years}>
                                    {years}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // EVENTNOM
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ color: 'white' }}>{t("Name of the event")} :</label>

                        <div style={{ // nom
                            width: '100%', marginBottom: '10px'
                        }}>
                            <select
                                value={eventName}
                                onChange={(e) => {
                                    setEventName(e.target.value);
                                    if (e.target.value !== "") {
                                        setFormulaireVisible(true);
                                    }
                                }}
                                required
                                style={{
                                    width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none',
                                }}>

                                <option value="">{t("Select an event")}</option>
                                {events.sort((a, b) => a.displayEventName?.localeCompare(b.displayEventName)).map((event, index) => (
                                    <option key={index} value={event.id}>
                                        {event.displayEventName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                {formulaireVisible ?
                    <>
                        <div>
                            <button onClick={handleDelete} style={{ margin: '10px', backgroundColor: 'red', color: 'white', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer' }}>
                                {t("Delete the event")}
                            </button>
                        </div>

                        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>

                            <div style={{ // Organisation
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Organization :</label>
                                <select
                                    value={org}
                                    onChange={(e) => setOrg(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an organization")}</option>
                                    {orgs.map((org, index) => (
                                        <option key={index} value={org}>
                                            {org}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // YEARS
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Year")} :</label>
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a year")}</option>
                                    {years.map((years, index) => (
                                        <option key={index} value={years}>
                                            {years}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // Name of the event
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Name of the event :</label>

                                <div style={{ // nom
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={displayEventName}
                                        onChange={(e) => setDisplayEventName(e.target.value)}
                                        required
                                        style={{
                                            width: '70%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }}
                                    />
                                </div>
                            </div>


                            <div style={{ // EARLY PRELIMS ?
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>EARLY PRELIMS ?</label>
                                <select
                                    value={ifEarlyPrelims}
                                    onChange={(e) => setIfEarlyPrelims(e.target.value === 'true')}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">YES or NO</option>
                                    {earlyPrelims.map((earlyPrelimsOption, index) => (
                                        <option key={index} value={earlyPrelimsOption.value}>
                                            {earlyPrelimsOption.label}
                                        </option>
                                    ))}
                                </select>

                            </div>

                            <label style={{ color: 'red', fontWeight: 'bold' }}>{t("ATTENTION SCHEDULE MODIFICATION: MAKE SURE TO MODIFY ALL SCHEDULES")}</label>

                            <div style={{ // DATE timestamp
                                width: '100%',
                            }}>
                                <label>Date event :</label>

                                <div style={{
                                    width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                                }}>

                                    <label>{selectedYear}</label>

                                    <div style={{ // MOIS
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedMonth}
                                            onChange={(e) => setSelectedMonth(e.target.value)}
                                            required
                                            style={{
                                                width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Month")}</label>

                                    </div>



                                    <div style={{ // jour
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedDateDay}
                                            onChange={(e) => setSelectedDateDay(e.target.value)}
                                            required
                                            style={{
                                                width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Day")}</label>
                                    </div>



                                    <div style={{ // HEURE
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 30,
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedDateHour}
                                            onChange={(e) => setSelectedDateHour(e.target.value)}
                                            required
                                            style={{
                                                width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Hour")}</label>
                                    </div>

                                    <div style={{ // MINUTE
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedDateMinute}
                                            onChange={(e) => setSelectedDateMinute(e.target.value)}
                                            required
                                            style={{
                                                width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Minute")}</label>
                                    </div>
                                </div>


                            </div>


                            {ifEarlyPrelims === true ?
                                <div style={{ // Early prelims time timestamp
                                    width: '100%',
                                }}>
                                    <label>If (Early prelims time) :</label>

                                    <div style={{
                                        width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                                    }}>

                                        <div style={{ // jour
                                            marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                        }}>
                                            <input
                                                type="text"
                                                value={selectedEarlyprelimsDay}
                                                onChange={(e) => setSelectedEarlyprelimsDay(e.target.value)}
                                                required
                                                style={{
                                                    width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                                }} />
                                            <label style={{ fontSize: 15, }}>{t("Day")}</label>
                                        </div>

                                        <div style={{ // HEURE
                                            marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                        }}>
                                            <input
                                                type="text"
                                                value={selectedEarlyprelimsHour}
                                                onChange={(e) => setSelectedEarlyprelimsHour(e.target.value)}
                                                required
                                                style={{
                                                    width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                                }} />
                                            <label style={{ fontSize: 15, }}>{t("Hour")}</label>
                                        </div>

                                        <div style={{ // MINUTE
                                            marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                        }}>
                                            <input
                                                type="text"
                                                value={selectedEarlyprelimsMinute}
                                                onChange={(e) => setSelectedEarlyprelimsMinute(e.target.value)}
                                                required
                                                style={{
                                                    width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                                }} />
                                            <label style={{ fontSize: 15, }}>{t("Minute")}</label>
                                        </div>
                                    </div>


                                </div>
                                : null}

                            <div style={{ //  prelims time timestamp
                                width: '100%',
                            }}>
                                <label>Prelims time :</label>

                                <div style={{
                                    width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                                }}>

                                    <div style={{ // jour
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedPrelimsDay}
                                            onChange={(e) => setSelectedPrelimsDay(e.target.value)}
                                            required
                                            style={{
                                                width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Day")}</label>
                                    </div>

                                    <div style={{ // HEURE
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedPrelimsHour}
                                            onChange={(e) => setSelectedPrelimsHour(e.target.value)}
                                            required
                                            style={{
                                                width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Hour")}</label>
                                    </div>

                                    <div style={{ // MINUTE
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedPrelimsMinute}
                                            onChange={(e) => setSelectedPrelimsMinute(e.target.value)}
                                            required
                                            style={{
                                                width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Minute")}</label>
                                    </div>
                                </div>


                            </div>

                            <div style={{ //  Main card time timestamp
                                width: '100%',
                            }}>
                                <label>Main card time :</label>

                                <div style={{
                                    width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                                }}>

                                    <div style={{ // jour
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedMaincardDay}
                                            onChange={(e) => setSelectedMaincardDay(e.target.value)}
                                            required
                                            style={{
                                                width: '60%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Day")}</label>
                                    </div>

                                    <div style={{ // HEURE
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedMaincardHour}
                                            onChange={(e) => setSelectedMaincardHour(e.target.value)}
                                            required
                                            style={{
                                                width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Hour")}</label>
                                    </div>

                                    <div style={{ // MINUTE
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                    }}>
                                        <input
                                            type="text"
                                            value={selectedMaincardMinute}
                                            onChange={(e) => setSelectedMaincardMinute(e.target.value)}
                                            required
                                            style={{
                                                width: '40%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Minute")}</label>
                                    </div>
                                </div>


                            </div>

                            <div style={{ // location
                                width: '100%',
                            }}>
                                <label>location :</label>

                                <div style={{ // place
                                    marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                }}>
                                    <input
                                        type="text"
                                        name="place"
                                        value={location?.place?.en}
                                        onChange={handleChange}
                                        required
                                        style={{
                                            width: '100%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ fontSize: 15, }}>location</label>

                                </div>

                                <div style={{ // arena
                                    marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                }}>
                                    <input
                                        type="text"
                                        name="arena"
                                        value={location.arena}
                                        onChange={handleChange}
                                        required
                                        style={{
                                            width: '100%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ fontSize: 15, }}>Arena</label>

                                </div>

                                <div style={{ // link
                                    marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                }}>
                                    <input
                                        type="text"
                                        name="link"
                                        value={location.link}
                                        onChange={handleChange}

                                        style={{
                                            width: '100%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ fontSize: 15, }}>{t("ticketing link")}</label>

                                </div>

                            </div>

                            <button
                                type="submit"
                                style={{
                                    padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                    color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                }}
                            >
                                {t("Submit")}
                            </button>
                        </form>
                    </>
                    : null}
            </div>
        </div>

    );
}

export function SubmitEventTV() {
    const { t } = useTranslation();
    const [events, setEvents] = useState([]);
    const [eventName, setEventName] = useState("");
    const [eventData, setEventData] = useState([]);

    const [org, setOrg] = useState("");

    const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];

    const currentYear = new Date().getFullYear();

    const [selectedYear, setSelectedYear] = useState(currentYear.toString());

    const [orgs, setOrgs] = useState([]);

    const [allPays, setAllPays] = useState([]);
    const [selectedPays, setSelectedPays] = useState('');

    const [allChannels, setAllChannels] = useState([]);

    const [selectedEarlyPrelimsChannel, setSelectedEarlyPrelimsChannel] = useState([]);
    const [selectedPrelimsChannel, setSelectedPrelimsChannel] = useState([]);
    const [selectedMainCardChannel, setSelectedMainCardChannel] = useState([]);

    const [channelEarlyPrelimsData, setChannelEarlyPrelimsData] = useState([]);
    const [channelPrelimsData, setChannelPrelimsData] = useState([]);
    const [channelMainCardData, setChannelMainCardData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    ////////////////////////

    useEffect(() => {
        const fetchPays = async () => {
            const querySnapshot = await getDocs(collection(db, "Admin", "AdminEvent", "tv"));
            const pays = [];
            querySnapshot.forEach((document) => {
                pays.push(document.id); // Assumons que l'ID du document est le nom du pays
            });
            setAllPays(pays);
        };

        fetchPays();
    }, []);

    useEffect(() => {

        const fetchChannel = async () => {
            if (selectedPays) {
                const querySnapshot = await getDocs(collection(db, "Admin", "AdminEvent", "tv", selectedPays, "channel"));
                const channels = [];
                querySnapshot.forEach((document) => {
                    channels.push(document.id); // Assumons que l'ID du document est le nom du pays
                });
                setAllChannels(channels);
            };
        };

        fetchChannel();

    }, [selectedPays]);

    useEffect(() => {
        const fetchEarlyPrelimsChannelsData = async () => {
            if (selectedPays && selectedEarlyPrelimsChannel.length > 0 && eventData.ifEarlyPrelims) {
                // Utilisation de Promise.all pour exécuter toutes les requêtes en parallèle
                const promises = selectedEarlyPrelimsChannel.map(async channel => {
                    const docRef = doc(db, "Admin", "AdminEvent", "tv", selectedPays, "channel", channel);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        return docSnap.data();
                    } else {
                        console.log(`No document for channel: ${channel}`);
                        return null;  // ou vous pourriez retourner une valeur par défaut ou une structure spécifique
                    }
                });

                const results = await Promise.all(promises);
                setChannelEarlyPrelimsData(results);
            }
        };

        fetchEarlyPrelimsChannelsData();
    }, [selectedPays, selectedEarlyPrelimsChannel]);

    useEffect(() => {
        const fetchPrelimsChannelsData = async () => {
            if (selectedPays && selectedPrelimsChannel.length > 0) {
                // Utilisation de Promise.all pour exécuter toutes les requêtes en parallèle
                const promises = selectedPrelimsChannel.map(async channel => {
                    const docRef = doc(db, "Admin", "AdminEvent", "tv", selectedPays, "channel", channel);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        return docSnap.data();
                    } else {
                        console.log(`No document for channel: ${channel}`);
                        return null;  // ou vous pourriez retourner une valeur par défaut ou une structure spécifique
                    }
                });

                const results = await Promise.all(promises);
                setChannelPrelimsData(results);
            }
        };

        fetchPrelimsChannelsData();
    }, [selectedPays, selectedPrelimsChannel]);


    useEffect(() => {
        const fetchMainCardChannelsData = async () => {
            if (selectedPays && selectedMainCardChannel.length > 0) {
                // Utilisation de Promise.all pour exécuter toutes les requêtes en parallèle
                const promises = selectedMainCardChannel.map(async channel => {
                    const docRef = doc(db, "Admin", "AdminEvent", "tv", selectedPays, "channel", channel);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        return docSnap.data();
                    } else {
                        console.log(`No document for channel: ${channel}`);
                        return null;  // ou vous pourriez retourner une valeur par défaut ou une structure spécifique
                    }
                });

                const results = await Promise.all(promises);
                setChannelMainCardData(results);
            }
        };

        fetchMainCardChannelsData();
    }, [selectedPays, selectedMainCardChannel]);

    //////////// Charger tous les events
    useEffect(() => {

        const fetchEvents = async () => {
            if (selectedYear && org) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear));
                const events = [];
                querySnapshot.forEach((document) => {
                    const data = document.data();
                    events.push({
                        id: document.id,               // Document ID
                        displayEventName: data.displayEventName || document.id  // Fallback to ID if displayEventName doesn't exist
                    });
                });
                setEvents(events);
            };
        };
        fetchEvents();


    }, [selectedYear, org]);

    // Charger les données de l'event sélectionné
    useEffect(() => {
        const fetchEventData = async () => {
            if (selectedYear && org && eventName) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setEventData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchEventData();
    }, [selectedYear, eventName, org]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        for (let i = 0; i < selectedEarlyPrelimsChannel.length; i++) {
            const channel = selectedEarlyPrelimsChannel[i];
            const specificChannelData = channelEarlyPrelimsData[i];

            if (specificChannelData) {
                try {
                    console.log(channelEarlyPrelimsData);
                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'early prelims channel', channel);
                    await setDoc(articleRef, {
                        id: specificChannelData.id,
                        link: specificChannelData.link,
                        logo: specificChannelData.logo,
                    });

                    setSelectedEarlyPrelimsChannel([]);
                    alert("EarlyPrelimsChannel soumis avec succès !");
                } catch (error) {
                    console.error("Error adding document: ", error);
                }
            }
        }

        for (let i = 0; i < selectedPrelimsChannel.length; i++) {
            const channel = selectedPrelimsChannel[i];
            const specificChannelData = channelPrelimsData[i];

            if (specificChannelData) {
                try {

                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'prelims channel', channel);
                    await setDoc(articleRef, {
                        id: specificChannelData.id,
                        link: specificChannelData.link,
                        logo: specificChannelData.logo,
                    });

                    setSelectedPrelimsChannel([]);
                    alert("PrelimsChannel soumis avec succès !");
                } catch (error) {
                    console.error("Error adding document: ", error);
                }
            }
        }

        for (let i = 0; i < selectedMainCardChannel.length; i++) {
            const channel = selectedMainCardChannel[i];
            const specificChannelData = channelMainCardData[i];

            if (specificChannelData) {
                try {

                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'main card channel', channel);
                    await setDoc(articleRef, {
                        id: specificChannelData.id,
                        link: specificChannelData.link,
                        logo: specificChannelData.logo,
                    });
                    setSelectedMainCardChannel([]);
                    alert("MainCard soumis avec succès !");
                } catch (error) {
                    console.error("Error adding document: ", error);
                }
            }
        }
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };


    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Submit")} TV event</h2>


                <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Organization :</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // YEARS
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Year")} :</label>
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a year")}</option>
                            {years.map((years, index) => (
                                <option key={index} value={years}>
                                    {years}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // EVENT
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Event :</label>
                        <select
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Name of the event")}</option>
                            {events.sort((a, b) => a.displayEventName?.localeCompare(b.displayEventName)).map((event, index) => (
                                <option key={index} value={event.id}>
                                    {event.displayEventName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // Pays
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Country")} :</label>
                        <select
                            value={selectedPays}
                            onChange={(e) => setSelectedPays(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a country")}</option>
                            {allPays.map((pays, index) => (
                                <option key={index} value={pays}>
                                    {pays}
                                </option>
                            ))}
                        </select>
                    </div>

                    {eventData.ifEarlyPrelims === true ?
                        <div style={{ // Channel early prelims:
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>early prelims {t("Broadcaster")}:</label>
                            <select

                                multiple={true}
                                value={selectedEarlyPrelimsChannel}
                                onChange={(e) => {
                                    const clickedOption = e.target.options[e.target.selectedIndex].value;
                                    let newSelectedOptions;

                                    if (selectedEarlyPrelimsChannel.includes(clickedOption)) {
                                        // Si l'option cliquée est déjà sélectionnée, retirez-la
                                        newSelectedOptions = selectedEarlyPrelimsChannel.filter(opt => opt !== clickedOption);
                                    } else {
                                        // Sinon, ajoutez-la aux options sélectionnées
                                        newSelectedOptions = [...selectedEarlyPrelimsChannel, clickedOption];
                                    }

                                    setSelectedEarlyPrelimsChannel(newSelectedOptions);
                                }}


                                style={{
                                    width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none',
                                }}
                            >
                                <option value="" disabled>{t("Select a broadcaster")}</option>

                                {allChannels.map((channels, index) => (
                                    <option key={index} value={channels}>
                                        {channels}
                                    </option>
                                ))}
                            </select>
                        </div>
                        : null}

                    <div style={{ // Channel prelims:
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>prelims {t("Broadcaster")}:</label>
                        <select

                            multiple={true}
                            value={selectedPrelimsChannel}
                            onChange={(e) => {
                                const clickedOption = e.target.options[e.target.selectedIndex].value;
                                let newSelectedOptions;

                                if (selectedPrelimsChannel.includes(clickedOption)) {
                                    // Si l'option cliquée est déjà sélectionnée, retirez-la
                                    newSelectedOptions = selectedPrelimsChannel.filter(opt => opt !== clickedOption);
                                } else {
                                    // Sinon, ajoutez-la aux options sélectionnées
                                    newSelectedOptions = [...selectedPrelimsChannel, clickedOption];
                                }

                                setSelectedPrelimsChannel(newSelectedOptions);
                            }}


                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="" disabled>{t("Select a broadcaster")}</option>

                            {allChannels.map((channels, index) => (
                                <option key={index} value={channels}>
                                    {channels}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // Channel main card:
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Main card {t("Broadcaster")}:</label>
                        <select

                            multiple={true}
                            value={selectedMainCardChannel}
                            onChange={(e) => {
                                const clickedOption = e.target.options[e.target.selectedIndex].value;
                                let newSelectedOptions;

                                if (selectedMainCardChannel.includes(clickedOption)) {
                                    // Si l'option cliquée est déjà sélectionnée, retirez-la
                                    newSelectedOptions = selectedMainCardChannel.filter(opt => opt !== clickedOption);
                                } else {
                                    // Sinon, ajoutez-la aux options sélectionnées
                                    newSelectedOptions = [...selectedMainCardChannel, clickedOption];
                                }

                                setSelectedMainCardChannel(newSelectedOptions);
                            }}


                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="" disabled>{t("Select a broadcaster")}</option>

                            {allChannels.map((channels, index) => (
                                <option key={index} value={channels}>
                                    {channels}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button
                        type="submit"
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                            color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                        }}
                    >
                        {t("Submit")}
                    </button>
                </form>
            </div>
        </div>

    );
}

export function UpdateEventTV() {
    const { t } = useTranslation();
    const [events, setEvents] = useState([]);
    const [eventName, setEventName] = useState("");
    const [eventData, setEventData] = useState([]);

    const [org, setOrg] = useState("");

    const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];

    const currentYear = new Date().getFullYear();

    const [selectedYear, setSelectedYear] = useState(currentYear.toString());

    const [orgs, setOrgs] = useState([]);

    const [allPays, setAllPays] = useState([]);
    const [selectedPays, setSelectedPays] = useState('');

    const [allChannels, setAllChannels] = useState([]);

    const [selectedEarlyPrelimsChannel, setSelectedEarlyPrelimsChannel] = useState([]);
    const [selectedPrelimsChannel, setSelectedPrelimsChannel] = useState([]);
    const [selectedMainCardChannel, setSelectedMainCardChannel] = useState([]);

    const [channelEarlyPrelimsData, setChannelEarlyPrelimsData] = useState([]);
    const [channelPrelimsData, setChannelPrelimsData] = useState([]);
    const [channelMainCardData, setChannelMainCardData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    ////////////////////////
    const [updateEarlyPrelimsChannels, setUpdateEarlyPrelimsChannels] = useState([]);
    const [updatePrelimsChannels, setUpdatePrelimsChannels] = useState([]);
    const [updateMainCardChannels, setUpdateMainCardChannels] = useState([]);
    const [formulaireVisible, setFormulaireVisible] = useState(false);

    useEffect(() => {
        setSelectedEarlyPrelimsChannel(updateEarlyPrelimsChannels ? updateEarlyPrelimsChannels : [])
    }, [updateEarlyPrelimsChannels]);

    useEffect(() => {
        setSelectedPrelimsChannel(updatePrelimsChannels ? updatePrelimsChannels : [])
    }, [updatePrelimsChannels]);

    useEffect(() => {
        setSelectedMainCardChannel(updateMainCardChannels ? updateMainCardChannels : [])
    }, [updateMainCardChannels]);

    ////////////////////////

    useEffect(() => { // RÉCUPÉRATION PAYS
        const fetchPays = async () => {
            const querySnapshot = await getDocs(collection(db, "Admin", "AdminEvent", "tv"));
            const pays = [];
            querySnapshot.forEach((document) => {
                pays.push(document.id); // Assumons que l'ID du document est le nom du pays
            });
            setAllPays(pays);
        };

        fetchPays();
    }, []);

    useEffect(() => {

        const fetchChannel = async () => {
            if (selectedPays) {
                const querySnapshot = await getDocs(collection(db, "Admin", "AdminEvent", "tv", selectedPays, "channel"));
                const channels = [];
                querySnapshot.forEach((document) => {
                    channels.push(document.id); // Assumons que l'ID du document est le nom du pays
                });
                setAllChannels(channels);
            };
        };
        fetchChannel();
    }, [selectedPays]);



    useEffect(() => {
        const fetchEarlyPrelimsChannelsData = async () => {
            if (selectedPays && selectedEarlyPrelimsChannel.length > 0 && eventData.ifEarlyPrelims) {
                // Utilisation de Promise.all pour exécuter toutes les requêtes en parallèle
                const promises = selectedEarlyPrelimsChannel.map(async channel => {
                    const docRef = doc(db, "Admin", "AdminEvent", "tv", selectedPays, "channel", channel);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        return docSnap.data();
                    } else {
                        console.log(`No document for channel: ${channel}`);
                        return null;  // ou vous pourriez retourner une valeur par défaut ou une structure spécifique
                    }
                });

                const results = await Promise.all(promises);
                setChannelEarlyPrelimsData(results);
            }
        };

        fetchEarlyPrelimsChannelsData();
    }, [selectedPays, selectedEarlyPrelimsChannel]);

    useEffect(() => {
        const fetchPrelimsChannelsData = async () => {
            if (selectedPays && selectedPrelimsChannel.length > 0) {
                // Utilisation de Promise.all pour exécuter toutes les requêtes en parallèle
                const promises = selectedPrelimsChannel.map(async channel => {
                    const docRef = doc(db, "Admin", "AdminEvent", "tv", selectedPays, "channel", channel);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        return docSnap.data();
                    } else {
                        console.log(`No document for channel: ${channel}`);
                        return null;  // ou vous pourriez retourner une valeur par défaut ou une structure spécifique
                    }
                });

                const results = await Promise.all(promises);
                setChannelPrelimsData(results);
            }
        };

        fetchPrelimsChannelsData();
    }, [selectedPays, selectedPrelimsChannel]);


    useEffect(() => {
        const fetchMainCardChannelsData = async () => {
            if (selectedPays && selectedMainCardChannel.length > 0) {
                // Utilisation de Promise.all pour exécuter toutes les requêtes en parallèle
                const promises = selectedMainCardChannel.map(async channel => {
                    const docRef = doc(db, "Admin", "AdminEvent", "tv", selectedPays, "channel", channel);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        return docSnap.data();
                    } else {
                        console.log(`No document for channel: ${channel}`);
                        return null;  // ou vous pourriez retourner une valeur par défaut ou une structure spécifique
                    }
                });

                const results = await Promise.all(promises);
                setChannelMainCardData(results);
            }
        };

        fetchMainCardChannelsData();
    }, [selectedPays, selectedMainCardChannel]);

    ///////// Charger tous les events
    useEffect(() => {

        const fetchEvents = async () => {
            if (selectedYear && org) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear));
                const events = [];
                querySnapshot.forEach((document) => {
                    const data = document.data();
                    events.push({
                        id: document.id,               // Document ID
                        displayEventName: data.displayEventName || document.id  // Fallback to ID if displayEventName doesn't exist
                    });
                });
                setEvents(events);
            };
        };
        fetchEvents();


    }, [selectedYear, org]);

    // Charger les données de l'event sélectionné
    useEffect(() => {
        const fetchEventData = async () => {
            if (selectedYear && org && eventName) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setEventData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchEventData();
    }, [selectedYear, eventName, org]);

    //////////// RÉCUPÉRATION DES DONNÉES TV

    useEffect(() => {

        const fetchEarlyPrelimsChannels = async () => {
            if (selectedYear && org && eventName && selectedPays) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'tv', selectedPays, 'early prelims channel'));
                const channels = [];
                querySnapshot.forEach((document) => {
                    channels.push(document.id);
                });
                setUpdateEarlyPrelimsChannels(channels);
            };
        };
        fetchEarlyPrelimsChannels();
    }, [selectedYear, org, eventName, selectedPays]);

    useEffect(() => {

        const fetchPrelimsChannels = async () => {

            if (selectedYear && org && eventName && selectedPays) {
                try {
                    const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'tv', selectedPays, 'prelims channel'));
                    const channels = [];
                    querySnapshot.forEach((document) => {
                        channels.push(document.id);
                    });
                    setUpdatePrelimsChannels(channels);
                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            };
        };
        fetchPrelimsChannels();
    }, [selectedYear, org, eventName, selectedPays]);

    useEffect(() => {

        const fetchMainCardChannels = async () => {

            if (selectedYear && org && eventName && selectedPays) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'tv', selectedPays, 'main card channel'));
                const channels = [];
                querySnapshot.forEach((document) => {
                    channels.push(document.id);
                });
                setUpdateMainCardChannels(channels);
            };
        };
        fetchMainCardChannels();
    }, [selectedYear, org, eventName, selectedPays]);


    ///////////
    const handleSubmit = async (e) => { // MODIFIER
        e.preventDefault();
        for (let i = 0; i < selectedEarlyPrelimsChannel.length; i++) { // PARCOURIR LES CHAINES EARLY PRELIMS
            const channel = selectedEarlyPrelimsChannel[i];
            const specificChannelData = channelEarlyPrelimsData[i];

            if (specificChannelData) {
                try {

                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'early prelims channel', channel);
                    await setDoc(articleRef, {
                        id: specificChannelData.id,
                        link: specificChannelData.link,
                        logo: specificChannelData.logo,
                    });

                    setSelectedEarlyPrelimsChannel([]);
                    alert("EarlyPrelimsChannel soumis avec succès !");
                } catch (error) {
                    console.error("Error adding document: ", error);
                }
            }
        }

        for (let i = 0; i < selectedPrelimsChannel.length; i++) { // PARCOURIR LES CHAINES PRELIMS
            const channel = selectedPrelimsChannel[i];
            const specificChannelData = channelPrelimsData[i];

            if (specificChannelData) {
                try {

                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'prelims channel', channel);
                    await setDoc(articleRef, {
                        id: specificChannelData.id,
                        link: specificChannelData.link,
                        logo: specificChannelData.logo,
                    });

                    setSelectedPrelimsChannel([]);
                    alert("PrelimsChannel soumis avec succès !");
                } catch (error) {
                    console.error("Error adding document: ", error);
                }
            }
        }

        for (let i = 0; i < selectedMainCardChannel.length; i++) { // PARCOURIR LES CHAINES MAIN CARD
            const channel = selectedMainCardChannel[i];
            const specificChannelData = channelMainCardData[i];

            if (specificChannelData) {
                try {

                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'main card channel', channel);
                    await setDoc(articleRef, {
                        id: specificChannelData.id,
                        link: specificChannelData.link,
                        logo: specificChannelData.logo,
                    });
                    setSelectedMainCardChannel([]);
                    alert("MainCard soumis avec succès !");
                } catch (error) {
                    console.error("Error adding document: ", error);
                }
            }
        }
        setOrg('');

        setEventName('');
        setSelectedPays('');
        setFormulaireVisible(false)

    };


    const handleDeleteTVEarlyPrelims = async () => { // SUPPRIMER

        for (let i = 0; i < selectedEarlyPrelimsChannel.length; i++) {// PARCOURIR LES CHAINES EARLY PRELIMS
            const channel = selectedEarlyPrelimsChannel[i];
            const specificChannelData = channelEarlyPrelimsData[i];

            if (specificChannelData) {

                try {
                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'early prelims channel', channel);
                    await deleteDoc(articleRef);



                    // Réinitialisez toutes les valeurs et cachez le formulaire si nécessaire
                } catch (error) {
                    console.error("Error deleting document: ", error);
                }

            }
        }
    };

    const handleDeleteTVPrelims = async () => { // SUPPRIMER


        for (let i = 0; i < selectedPrelimsChannel.length; i++) { // PARCOURIR LES CHAINES PRELIMS
            const channel = selectedPrelimsChannel[i];
            const specificChannelData = channelPrelimsData[i];

            if (specificChannelData) {
                try {

                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'prelims channel', channel);
                    await deleteDoc(articleRef);



                    // Réinitialisez toutes les valeurs et cachez le formulaire si nécessaire
                } catch (error) {
                    console.error("Error deleting document: ", error);
                }
            }
        }

    };

    const handleDeleteTVMainCard = async () => { // SUPPRIMER

        for (let i = 0; i < selectedMainCardChannel.length; i++) { // PARCOURIR LES CHAINES MAIN CARD
            const channel = selectedMainCardChannel[i];
            const specificChannelData = channelMainCardData[i];

            if (specificChannelData) {
                try {

                    const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, "tv", selectedPays, 'main card channel', channel);
                    await deleteDoc(articleRef);



                    // Réinitialisez toutes les valeurs et cachez le formulaire si nécessaire
                } catch (error) {
                    console.error("Error deleting document: ", error);
                }
            }
        }

    };


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };


    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Update")} TV event</h2>

                <div style={{ backgroundColor: '#ff3030', padding: '20px', borderRadius: 10 }}>
                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Organization :</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // YEARS
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Year")} :</label>
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a year")}</option>
                            {years.map((years, index) => (
                                <option key={index} value={years}>
                                    {years}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // EVENT
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Event :</label>
                        <select
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an event")}</option>
                            {events.sort((a, b) => a.displayEventName?.localeCompare(b.displayEventName)).map((event, index) => (
                                <option key={index} value={event.id}>
                                    {event.displayEventName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // Pays
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Country")} :</label>
                        <select
                            value={selectedPays}
                            onChange={(e) => {
                                setSelectedPays(e.target.value); if (e.target.value !== "") {
                                    setFormulaireVisible(true);
                                }
                            }}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a country")}</option>
                            {allPays.map((pays, index) => (
                                <option key={index} value={pays}>
                                    {pays}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {formulaireVisible ?

                    <>
                        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
                            {eventData.ifEarlyPrelims === true ?
                                <div style={{ // Channel early prelims:
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <label>early prelims {t("Broadcaster")}:</label>
                                    <select

                                        multiple={true}
                                        value={selectedEarlyPrelimsChannel}
                                        onChange={(e) => {
                                            const clickedOption = e.target.options[e.target.selectedIndex].value;
                                            let newSelectedOptions;


                                            if (selectedEarlyPrelimsChannel.includes(clickedOption)) {
                                                // Si l'option cliquée est déjà sélectionnée, retirez-la
                                                newSelectedOptions = selectedEarlyPrelimsChannel.filter(opt => opt !== clickedOption);
                                                handleDeleteTVEarlyPrelims()
                                            } else {
                                                // Sinon, ajoutez-la aux options sélectionnées
                                                newSelectedOptions = [...selectedEarlyPrelimsChannel, clickedOption];
                                            }

                                            setSelectedEarlyPrelimsChannel(newSelectedOptions);
                                        }}


                                        style={{
                                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none',
                                        }}
                                    >
                                        <option value="" disabled>{t("Select a broadcaster")}</option>

                                        {allChannels.map((channels, index) => (
                                            <option key={index} value={channels}>
                                                {channels}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                : null}

                            <div style={{ // Channel prelims:
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>prelims {t("Broadcaster")}:</label>
                                <select

                                    multiple={true}
                                    value={selectedPrelimsChannel}
                                    onChange={(e) => {
                                        const clickedOption = e.target.options[e.target.selectedIndex].value;
                                        let newSelectedOptions;

                                        if (selectedPrelimsChannel.includes(clickedOption)) {
                                            // Si l'option cliquée est déjà sélectionnée, retirez-la
                                            newSelectedOptions = selectedPrelimsChannel.filter(opt => opt !== clickedOption);
                                            handleDeleteTVPrelims()
                                        } else {
                                            // Sinon, ajoutez-la aux options sélectionnées
                                            newSelectedOptions = [...selectedPrelimsChannel, clickedOption];
                                        }

                                        setSelectedPrelimsChannel(newSelectedOptions);
                                    }}


                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="" disabled>{t("Select a broadcaster")}</option>

                                    {allChannels.map((channels, index) => (
                                        <option key={index} value={channels}>
                                            {channels}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // Channel main card:
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Main card {t("Broadcaster")}:</label>
                                <select

                                    multiple={true}
                                    value={selectedMainCardChannel}
                                    onChange={(e) => {
                                        const clickedOption = e.target.options[e.target.selectedIndex].value;
                                        let newSelectedOptions;

                                        if (selectedMainCardChannel.includes(clickedOption)) {
                                            // Si l'option cliquée est déjà sélectionnée, retirez-la
                                            newSelectedOptions = selectedMainCardChannel.filter(opt => opt !== clickedOption);
                                            handleDeleteTVMainCard()
                                        } else {
                                            // Sinon, ajoutez-la aux options sélectionnées
                                            newSelectedOptions = [...selectedMainCardChannel, clickedOption];
                                        }

                                        setSelectedMainCardChannel(newSelectedOptions);
                                    }}


                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="" disabled>{t("Select a broadcaster")}</option>

                                    {allChannels.map((channels, index) => (
                                        <option key={index} value={channels}>
                                            {channels}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <button
                                type="submit"
                                style={{
                                    padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                    color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                }}
                            >
                                {t("Submit")}
                            </button>
                        </form>
                    </>
                    : null}
            </div>
        </div>

    );
}